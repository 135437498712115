var ricetta = function ()
{
    var ricetta = this;

    this.bindEvents = function ()
    {

    };

    this.loadIngredienti = function ()
    {
        if ($('#ricetta_ingredienti_div').length > 0) {
            $.get(app.baseUrl + "/ricetta_ingredienti", {idRicetta: $("#frmRicetta").attr('data-id')})
                .done(function (data) {
                    $('#ricetta_ingredienti_div').html(data);
                })
                .fail(function (data) {
                    app.error("", "Errore!");
                });
        }
    };
};