var voce = function(voceCrud) {

    this.bindEvents = function() {
        var dropDocumentoEsternoDdt = $("#ddt_documento_esterno_file").dropzone({
            autoProcessQueue: false,
            dictMaxFilesExceeded: "Hai superato il limite massimo di file caricabili.<br>Non puoi caricare più di 1 file per volta.",
			dictFileTooBig: "Il file è troppo grande. Grandezza massima "+ $("#ddt_documento_esterno_file").attr('data-max') +" (Mb)",
            url: "#",
            headers: $.ajaxSetup().headers,
            addRemoveLinks: true,
            dictRemoveFile: $("#ddt_documento_esterno_file").attr('data-remove'),
            dictRemoveFileConfirmation: T.uploadRemove,
            maxFiles: 1,
            clickable: true,
            maxFilesize: $("#ddt_documento_esterno_file").attr('data-max'),
            init: function () {

                var size = $("#ddt_documento_esterno_file").attr('data-size');
                var name = $("#ddt_documento_esterno_file").attr('data-name');
                var extension = $("#ddt_documento_esterno_file").attr('data-extension');

                var self = this;
                if($.trim(size) !== '0') {
                    // oggetto file per dropzone
                    var file = {
                        name: name,
                        size: size,
                        status: 'success',
                        accepted: true
                    };

                    var url = getUrlThumbnail(app.baseUrl + "/docry_documento_esterno/" + $("#ddt_documento_esterno_file").attr('data-id') + "/download?always_download=1&v="+ Math.random(),extension);

                    // fingo di aggiungere un nuovo documento a dropzone
                    self.emit("addedfile", file);
                    self.createThumbnailFromUrl(file, url);
                    self.emit("complete", file);

                    // aggiungo il mio file all'array
                    self.files.push(file);
                }
            },
            sending: function (file, data) {
                $("#spinner").show();
            },
            success: function (file, data) {
                $("#spinner").hide();
                if (!data.response) {
                    app.warning("", data.message);
                    this.removeFile(file);
                }
            },
            error: function (file, data) {
                app.warning("", data);
                $("#spinner").hide();
                this.removeFile(file);
            },
            removedfile: function (file) {
                if (file.status === "success") {
                    $("#spinner").show();

                }

                $("#ddt_documento_esterno_exist").val("");
                $(document).find(file.previewElement).remove();
            },
            maxfilesexceeded: function (file) {
                app.warning("Hai superato il limite massimo di file caricabili");
                $(document).find(file.previewElement).remove();
            }
        });

        var dropDocumentoEsternoFattura = $("#fattura_documento_esterno_file").dropzone({
            autoProcessQueue: false,
            dictMaxFilesExceeded: "Hai superato il limite massimo di file caricabili.<br>Non puoi caricare più di 1 file per volta.",
			dictFileTooBig: "Il file è troppo grande. Grandezza massima "+ $("#fattura_documento_esterno_file").attr('data-max') +" (Mb)",
            url: "#",
            headers: $.ajaxSetup().headers,
            addRemoveLinks: true,
            dictRemoveFile: $("#fattura_documento_esterno_file").attr('data-remove'),
            dictRemoveFileConfirmation: T.uploadRemove,
            maxFiles: 1,
            clickable: true,
            maxFilesize: $("#fattura_documento_esterno_file").attr('data-max'),
            init: function () {
                var size = $("#fattura_documento_esterno_file").attr('data-size');
                var name = $("#fattura_documento_esterno_file").attr('data-name');
                var extension = $("#fattura_documento_esterno_file").attr('data-extension');

                var self = this;
                if($.trim(size) !== '0') {
                    // oggetto file per dropzone
                    var file = {
                        name: name,
                        size: size,
                        status: 'success',
                        accepted: true
                    };

                    var url = getUrlThumbnail(app.baseUrl + "/docry_documento_esterno/" + $("#fattura_documento_esterno_file").attr('data-id') + "/download?always_download=1&v="+ Math.random(),extension);

                    // fingo di aggiungere un nuovo documento a dropzone
                    self.emit("addedfile", file);
                    self.createThumbnailFromUrl(file, url);
                    self.emit("complete", file);

                    // aggiungo il mio file all'array
                    self.files.push(file);
                }
            },
            sending: function (file, data) {
                $("#spinner").show();
            },
            success: function (file, data) {
                $("#spinner").hide();
                if (!data.response) {
                    app.warning("", data.message);
                    this.removeFile(file);
                }
            },
            error: function (file, data) {
                app.warning("", data);
                $("#spinner").hide();
                this.removeFile(file);
            },
            removedfile: function (file) {
                if (file.status === "success") {
                    $("#spinner").show();

                }

                $("#fattura_documento_esterno_exist").val("");
                $(document).find(file.previewElement).remove();
            },
            maxfilesexceeded: function (file) {
                app.warning("Hai superato il limite massimo di file caricabili");
                $(document).find(file.previewElement).remove();
            }
        });

        var dropDocumentoEsternoVoce = $("#documento_esterno_file").dropzone({
            autoProcessQueue: false,
            dictMaxFilesExceeded: "Hai superato il limite massimo di file caricabili.<br>Non puoi caricare più di 1 file per volta.",
			dictFileTooBig: "Il file è troppo grande. Grandezza massima "+ $("#documento_esterno_file").attr('data-max') +" (Mb)",
            url: "#",
            headers: $.ajaxSetup().headers,
            addRemoveLinks: true,
            dictRemoveFile: $("#documento_esterno_file").attr('data-remove'),
            dictRemoveFileConfirmation: T.uploadRemove,
            maxFiles: 1,
            clickable: true,
            maxFilesize: $("#documento_esterno_file").attr('data-max'),
            init: function () {
                var size = $("#documento_esterno_file").attr('data-size');
                var name = $("#documento_esterno_file").attr('data-name');
                var extension = $("#documento_esterno_file").attr('data-extension');

                var self = this;
                if($.trim(size) !== '0') {
                    // oggetto file per dropzone
                    var file = {
                        name: name,
                        size: size,
                        status: 'success',
                        accepted: true
                    };

                    var url = getUrlThumbnail(app.baseUrl + "/docry_documento_esterno/" + $("#documento_esterno_file").attr('data-id') + "/download?always_download=1&v="+ Math.random(),extension);

                    // fingo di aggiungere un nuovo documento a dropzone
                    self.emit("addedfile", file);
                    self.createThumbnailFromUrl(file, url);
                    self.emit("complete", file);

                    // aggiungo il mio file all'array
                    self.files.push(file);
                }
            },
            sending: function (file, data) {
                $("#spinner").show();
            },
            success: function (file, data) {
                $("#spinner").hide();
                if (!data.response) {
                    app.warning("", data.message);
                    this.removeFile(file);
                }
            },
            error: function (file, data) {
                app.warning("", data);
                $("#spinner").hide();
                this.removeFile(file);
            },
            removedfile: function (file) {
                if (file.status === "success") {
                    $("#spinner").show();

                }

                $("#docry_documento_esterno_exist").val("");
                $(document).find(file.previewElement).remove();
            },
            maxfilesexceeded: function (file) {
                app.warning("Hai superato il limite massimo di file caricabili");
                $(document).find(file.previewElement).remove();
            }
        });

        if (voceCrud) {
            voceCrud.getCustomFormData = function (data) {
                if (typeof (dropDocumentoEsternoDdt[0]) != "undefined" && dropDocumentoEsternoDdt[0] !== null) {
                    for (var j = 0; j < dropDocumentoEsternoDdt[0].dropzone.files.length; j++) {
                        if (dropDocumentoEsternoDdt[0].dropzone.files[j].status === 'queued') {
                            data.append('ddt_documento_esterno_file', dropDocumentoEsternoDdt[0].dropzone.files[j], dropDocumentoEsternoDdt[0].dropzone.files[j].name);
                        }
                    }
                }

                if (typeof (dropDocumentoEsternoFattura[0]) != "undefined" && dropDocumentoEsternoFattura[0] !== null) {
                    for (var j = 0; j < dropDocumentoEsternoFattura[0].dropzone.files.length; j++) {
                        if (dropDocumentoEsternoFattura[0].dropzone.files[j].status === 'queued') {
                            data.append('fattura_documento_esterno_file', dropDocumentoEsternoFattura[0].dropzone.files[j], dropDocumentoEsternoFattura[0].dropzone.files[j].name);
                        }
                    }
                }

                if (typeof (dropDocumentoEsternoVoce[0]) != "undefined" && dropDocumentoEsternoVoce[0] !== null) {
                    for (var j = 0; j < dropDocumentoEsternoVoce[0].dropzone.files.length; j++) {
                        if (dropDocumentoEsternoVoce[0].dropzone.files[j].status === 'queued') {
                            data.append('documento_esterno_file', dropDocumentoEsternoVoce[0].dropzone.files[j], dropDocumentoEsternoVoce[0].dropzone.files[j].name);
                        }

                    }
                }

                return data;
            };
        }

    };

    this.bindEvents();
};
