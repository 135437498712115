var lotto = function () {

    var lotto = this;

    this.selectLotto = function (id, qta, id_input_qta) {
        var qtaInput = $("#"+id_input_qta).val() || 0;
        var qtaMax = $("#"+id_input_qta).attr('data-max-value');

        // console.log(qtaInput);
        // console.log(qtaMax);
        // console.log((qtaInput > qtaMax) );


        if (parseFloat(qtaInput) > parseFloat(qtaMax)) {
            app.error("Attenzione","La quantità massima disponibile per questo lotto è di "+qtaMax+"!");
            return;
        }
        qta = qtaInput;
        if (typeof callbackSelectLotto != 'undefined') {
            var fnz = eval(callbackSelectLotto);
            if (typeof fnz == 'function') {
                fnz(id, qta);
            }
        }
    };

    this.addLottoToWip = function (id, qta) {
        var $oraInizio = $("#data_ora_inizio_wip");
        var $id_locale_wip = $("#id_locale_wip");

        var id_locale_wip_val = $id_locale_wip.val();

        var data_inizio_wip = $oraInizio.val();
        var id_wip = $("#id_lotto_wip").val();

        if ($oraInizio.val() != "") {
            if (id_locale_wip_val != "" && id_locale_wip_val != 0) {
                $.post("lotto/add_component_to_lotto", {
                        data_inizio_wip: data_inizio_wip,
                        id_wip: id_wip,
                        id_lotto_componente: id,
                        qta: qta,
                        id_locale_wip_val: id_locale_wip_val
                    })
                    .success(function (data) {
                        if (data.response) {
                            if (id_wip == 0)
                                $("#id_lotto_wip").val(data.message);
                            lotto.popolaDivLotti(data.message);
                        } else
                            app.warning("ATTENZIONE", data.message);
                    });
            } else {
                app.warning("Per procedere è necessario selezionare un locale per il Wip");
                $id_locale_wip.focus();
            }
        } else {
            app.warning("Per procedere è necessario valorizzare la data di inizio Wip");
            $oraInizio.focus();
        }
    };

    /**
     * Richiede all'utente i dati necessari alla generazione di un nuovo lorro collegato al dettaglio documento attuale
     * @param idDettaglioDocumento
     */
    this.generaLottoPerDettaglioDocumento = function (idDettaglioDocumento) {

        $.get('lotto/create', {id_dettaglio_documento: idDettaglioDocumento}, function (data) {

            $("#myModal").find(".modal-title").hide();
            $("#myModal").find(".modal-header").css('height', "10px");
            $("#myModal").find(".modal-header .close").css('margin-top', "-15px");
            $("#myModal").find(".modal-title").hide();
            $("#myModal").find(".modal-body").html(data);
            $("#myModal").find(".btn-primary").html("Crea");
            $("#myModal").find(".modal-footer").hide();

            $("#myModal [data-interaction=back]").hide();
            $("#myModal #frmLotto [data-interaction=save]").removeAttr("data-href");
            $("#myModal #frmLotto [data-interaction=save]").attr("data-callback", 'objLotto.closeModalCreaLotto');

            $("#myModal").modal("show");

            // aspetta giusto un attimo affinchè la select2 prenda le dimensioni del contenitore
            setTimeout(function () {
                app.runBind();
            }, 200);

        });

    };

    this.closeModalCreaLotto = function () {
        dtDettagliDocumento.table.draw("page");
        $("#myModal").find(".close").click();
    };

    this.popolaDivLotti = function (idLotto) {

        idLotto = idLotto || 0;

        $.get("lotti_disponibili?dt=wip")
            .success(function (data) {
                $("#divComponentiLotto").html(data);
            });
        $.get("lotti_dettagli_composizione?idLotto=" + idLotto)
            .success(function (data) {
                $("#divWip").html(data);
            });
    };

    this.bindEvent = function () {

        $('[data-interaction=checkLottoTrack]').unbind('click').bind('click', function () {
            var codice = $("#codice").val() || 0;
            app.block(1);
            $.post("lotto/get_tracciabilita", {
                    codice: codice
                })
                .success(function (data) {
                    if (data.response) {
                        $("#lottiContainer").show();
                        /*$.get("lotti_disponibili", {ids: data.message.materie_prime, tracciabilita : 1, tipo: "mp", codice_wip: data.message.first_wip, tracciabilita: 1}, function (dataMp) {
                            $("#materiePrimeContainer").html(dataMp);
                        });
                        $.get("lotti_disponibili", {ids: data.message.semilavorati, tracciabilita : 1, tipo: "sl"}, function (dataSl) {
                            $("#semilavoratiContainer").html(dataSl);
                        });
                        $.get("lotti_disponibili", {ids: data.message.prodotti_finiti, tracciabilita : 1, tipo: "pf"}, function (dataPf) {
                            $("#prodottiFinitiContainer").html(dataPf);
                        });
                        */
                        $.get("lotti_dettagli_composizione", {ids: data.message.composizione_lotto, dt: "tracciabilita", tipo: "mp", id_lotto: data.id_lotto}, function (dataMp) {
                            $("#materiePrimeContainer").html(dataMp);
                        });
                        $.get("lotti_dettagli_composizione", {ids: data.message.composizione_lotto, dt: "tracciabilita", tipo: "sl", id_lotto: data.id_lotto}, function (dataMp) {
                            $("#semilavoratiContainer").html(dataMp);
                        });
                        $.get("lotti_dettagli_composizione", {ids: data.message.composizione_lotto, dt: "tracciabilita", tipo: "pf", id_lotto: data.id_lotto}, function (dataMp) {
                            $("#prodottiFinitiContainer").html(dataMp);
                        });
                        $.get("documento", {ids: data.message.fatture, tracciabilita : 1, codice: codice}, function (dataf) {
                            $("#fattureContainer").html(dataf);
                        });

                        $('[data-interaction=stampa_tracciabilita]').show();
                        $('[data-interaction=stampa_tracciabilita]').unbind('click').bind('click', function () {
                            app.locationHref(app.baseUrl + "/lotto/stampa_tracciabilita?id_lotto=" + data.id_lotto, true, false);
                        });

                    } else {
                        app.warning(data.message);
                    }

                    app.block(0);
                });

        });

        $('#qta').unbind('change').bind('change', function () {
            var qta = $('#qta').val();
            var percIngPrincipali = $('#id_ricetta option:selected').attr('data-qta');
            if (percIngPrincipali != undefined && percIngPrincipali != 0) {
                var qtaTotale = parseFloat(100 * qta / percIngPrincipali);
                $("#qta_totale").val(qtaTotale);
            }
        });

        $('[data-interaction=usaRicetta]').unbind('click').bind('click', function () {
            /*var all_ingredienti = 0;
            if (confirm("Utilizzare sia gli ingredienti principali che quelli secondari"))
                all_ingredienti = 1;*/

            bootbox.dialog({
                message: "<span class='bigger-110'>Procedere con l'utilizzo della ricetta?</span>",
                buttons:
                {
                    "success" :
                    {
                        "label" : "<i class='icon-ok'></i>Procedere utilizzando sia gli ingredienti principali che quelli secondari",
                        "className" : "btn-sm btn-success",
                        "callback": function() {
                            lotto.usaRicetta(1);
                        }
                    },
                    "click" :
                    {
                        "label" : "Procedere utilizzando solo gli ingredienti secondari",
                        "className" : "btn-sm btn-primary",
                        "callback": function() {
                            lotto.usaRicetta(0);
                        }
                    },
                    "danger" :
                    {
                        "label" : "Annulla",
                        "className" : "btn-sm btn-danger",
                        "callback": function() {
                        }
                    },
                }
            });


        });


        $('[data-interaction=showDivConfirmLotto]').unbind('click').bind('click', function () {
            var id_wip = $("#id_lotto_wip").val() || 0;
            $.post("lotto/get_qta", {
                    id_wip: id_wip,
                })
                .success(function (data) {
                    if (data.response) {
                        $("#peso_totale").val(data.message);
                        $("#peso_totale").attr('data-peso-totale',data.message);
                    } else
                        app.warning("ATTENZIONE", "Impossibile calcolare il peso totale");
                });


            $("#buttonProcediDiv").hide();
            $("#confirmLotto").show();
        });

        $('[data-interaction=delete_lotto]').unbind('click').bind('click', function () {
            if (confirm("Conferma l'eliminazione del lotto in lavorazione?")) {

                var id_wip = $("#id_lotto_wip").val() || 0;

                var url = $(this).attr("data-url");
                var url_href = $(this).attr("data-href");

                lotto.delete_lotto(id_wip, url, url_href);
            }
        });

        $('[data-interaction=close_wip]').unbind('click').bind('click', function () {
            if (confirm("Conferma la conclusione della lavorazione e la creazione del nuovo lotto?")) {

                var url = $(this).attr("data-url");
                var url_href = $(this).attr("data-href");

                var id_wip = $("#id_lotto_wip").val() || 0;

                var id_prodotto_destinazionazione = $("#id_prodotto_destinazionazione").val();
                var id_locale_ubicazione = $("#id_locale_ubicazione").val();
                var data_ora_fine_wip = $("#data_ora_fine_wip").val();
                var peso_totale = $("#peso_totale").val();

                var peso_totale_da_somma = $("#peso_totale").attr('data-peso-totale');
                var prosegui = true;
                if (parseFloat(peso_totale) != parseFloat(peso_totale_da_somma)) {
                    prosegui = false;
                    if (confirm("Il valore del peso è diverso dalla somma degli ingredienti, continuare comunque?"))
                        prosegui = true;
                }

                if (prosegui)
                    lotto.close_wip(url, url_href, id_wip,  id_prodotto_destinazionazione,id_locale_ubicazione, data_ora_fine_wip, peso_totale );
            }
        });

        // RICETTA
        $('[data-interaction=ricetta]').unbind('click').bind('click', function () {
            // TODO
        });
    };

    this.delete_riduzione = function (id, url, dt) {
        if (id != 0) {
            if (confirm("Confermare di non voler effettuare la riduzione di peso selezionata?")) {
                $.post(url, {id: id})
                    .success(function (data) {
                        if (!(data.response)) {
                            app.warning("ATTENZIONE", data.message);
                        } else
                            app.success("Bypass riduzione peso programmata","Operazione eseguita correttamente");
                            dt.redrawPage();
                    });
            }
        } else
            app.warning("Bypass riduzione peso programmata", "Impossibile procedere in quanto non è possibile identificare la riduzione di peso");
    };

    this.delete_lotto = function (id, url, url_href) {
        if (id != 0) {
            $.post(url, {id: id})
                .success(function (data) {
                    if (!(data.response)) {
                        app.warning("ATTENZIONE", data.message);
                    } else
                        app.href(url_href);
                });
        } else
            app.href(url_href);
    };

    this.execute_riduzione_do_operation = function (id, url, dt) {
        $.post(url, {id: id})
            .success(function (data) {
                if (!(data.response)) {
                    app.warning("ATTENZIONE", data.message);
                } else
                    app.success("Esecuzione riduzione peso programmata","Operazione eseguita correttamente");
                dt.redrawPage();
            });
    };

    this.execute_riduzione = function (id, url, dt) {
        if (id != 0) {
            if (confirm("Confermare di voler effettuare la riduzione di peso selezionata?")) {
                this.execute_riduzione_do_operation(id, url, dt);
            }
        } else
            app.warning("Bypass riduzione peso programmata", "Impossibile procedere in quanto non è possibile identificare la riduzione di peso");
    };

    this.close_wip = function(url, url_href, id_wip,  id_prodotto_destinazionazione,id_locale_ubicazione, data_ora_fine_wip, peso_totale ) {

        $.post(url, {
            id_wip: id_wip,
            id_prodotto_destinazionazione: id_prodotto_destinazionazione,
            id_locale_ubicazione: id_locale_ubicazione,
            data_ora_fine_wip:data_ora_fine_wip,
            peso_totale:peso_totale
        } )
            .success(function (data) {
                //debugger;
                if (!(data.response)) {
                    app.warning("ATTENZIONE", data.message);
                } else {
                    app.success("", "Finito");
                    app.href(app.baseUrl + "/#lotti_disponibili");
                }
            })
            .error(function (data) {
                console.log(data);
        });
    };

    this.loadDT = function (configuration) {
        $.get(app.baseUrl + "/lotto", {dt: configuration}, function (data) {
            $("#" + configuration).html(data);
        });
    };

    this.usaRicetta = function (all_ingredienti) {
        var $oraInizio = $("#data_ora_inizio_wip");
        var $id_locale_wip = $("#id_locale_wip");

        var id_locale_wip_val = $id_locale_wip.val();

        var data_inizio_wip = $oraInizio.val();

        var id_ricetta = $("#id_ricetta").val() || 0;

        var qtaTotale = $("#qta_totale").val();

        var id_wip = $("#id_lotto_wip").val() || 0;


        if (qtaTotale > 0 ) {
            if (id_ricetta > 0 ) {
                if ($oraInizio.val() != "") {
                    if (id_locale_wip_val != "" && id_locale_wip_val != 0) {
                        $.post("lotto/usa_ricetta", {
                                data_inizio_wip: data_inizio_wip,
                                id_ricetta: id_ricetta,
                                qta: qtaTotale,
                                id_locale_wip_val: id_locale_wip_val,
                                all_ingredienti: all_ingredienti,
                                id_wip: id_wip
                            })
                            .success(function (data) {
                                if (data.response) {
                                    $("#responseRicettaDiv").html(data.message.message);
                                    console.log(data);
                                    $("#id_lotto_wip").val(data.message.id_wip);
                                    lotto.popolaDivLotti(data.message.id_wip);

                                } else
                                    app.warning("ATTENZIONE", data.message);
                            });
                    } else {
                        app.warning("Per procedere è necessario selezionare un locale per il Wip");
                        $id_locale_wip.focus();
                    }
                } else {
                    app.warning("Per procedere è necessario valorizzare la data di inizio Wip");
                    $oraInizio.focus();
                }
            } else {
                app.warning("Per procedere è necessario selezionare una ricetta");
                $("#id_ricetta").focus();
            }
        } else {
            app.warning("Per procedere è necessario indicare una quantita totale maggiore di 0");
            $("#qta_totale").focus();
        }
    }

    this.bindEvent();
};