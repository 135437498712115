var contract_detail_payment = function(){
    var contract_detail_payment = this;

    this.creaNuovoContrattoDettaglioPagamento = function(dt, id_contract_detail){
        $.post("contract_detail_payment", {id_contract_detail: id_contract_detail}, function (data) {
            if (data.response) {
                dt.table.draw("page");
            } else {
                app.error(data.message);
            }
        });
    };
};