var report_fatture = function (dt)
{
    var report_fatture = this;

    this.bindEvets = function ()
    {

    };

    this.stampa = function ()
    {
        var ids = [];
        $.each(dt.table.data(), function () {
            if (this.length > 0)
                ids.push(this[0]);
        });

        if (ids.length == 0) {
            app.warning("", "Nessun elemento!");
            return;
        }

        app.block(1);
        $.post(app.baseUrl + "/report_fatture/genera", {ids: ids})
            .done(function (data) {
                if (data.response) {
                    report_fatture.startInterval();
                } else {
                    app.warning("", data.message);
                }

                app.block(0);
            })
            .fail(function (data) {
                app.block(0);
                var error = "Errore AJAX!";
                if (typeof data.status != 'undefined' &&
                    data.status == 422 &&
                    typeof data.responseJSON == 'object' &&
                    typeof data.responseJSON.value != 'undefined' &&
                    data.responseJSON.value.length > 0
                ) {
                    error = data.responseJSON.value[0];
                }
                app.error("", error);
            });
    };

    this.verificaGenerazioneDocumenti = function () {
        $.post(app.baseUrl + "/report_fatture/verifica_coda")
            .done(function (data) {
                if (data.response) {
                    $("#" + dt.idTable + "_wrapper").find("[data-interaction='coda_stampa']").html("( " + data.message + " rimanenti )");
                    $("#" + dt.idTable + "_wrapper").find("[data-interaction=generaStampa]").removeClass("fa-cogs").addClass("fa-spinner fa-spin blue");
                    $("#" + dt.idTable + "_wrapper").find("[data-interaction=generaStampa]").closest('button').attr('disabled', 'disabled');
                } else {
                    $("#" + dt.idTable + "_wrapper").find("[data-interaction='coda_stampa']").html("");
                    $("#" + dt.idTable + "_wrapper").find("[data-interaction=generaStampa]").removeClass("fa-spinner fa-spin green").addClass("fa-cogs");
                    $("#" + dt.idTable + "_wrapper").find("[data-interaction=generaStampa]").closest('button').removeAttr('disabled');

                    $('[data-interaction=downloadLast]').closest('button').removeClass('hide');

                    // faccio il clear dell'intervallo
                    report_fatture.clearInterval();
                }
            });
    };

    this.clearInterval = function () {
        // se un interval è già in esecuzione lo elimino
        if (typeof report_fatture.constructor.interval != "undefined")
            clearInterval(report_fatture.constructor.interval);
    };

    this.startInterval = function () {
        this.clearInterval();

        report_fatture.constructor.interval = setInterval(function () {
            report_fatture.verificaGenerazioneDocumenti();
        }, 10000);

        report_fatture.verificaGenerazioneDocumenti();
    };
};

report_fatture.interval = undefined;