var sepa_schedule = function (dt, configurationName)
{
    var sepa_schedule = this;

    this.bindEvents = function ()
    {
        $("#" + dt.idTable + "_wrapper").find("#" + dt.idTable + "_filter").find('.dropdown-toggle.set_banca').each(function () {
            var $div = $('<div class="dropdown" style="display: inline-block;"></div>');
            $(this).attr('data-toggle', 'dropdown');
            $div.html($(this).clone());

            var $ul = $('<ul class="dropdown-menu"></ul>');
            $("#id_banca").find('option').each(function () {
                $ul.append('<li><a style="cursor: pointer;" data-interaction="banca" data-id="' + $(this).attr('value') + '">' + $(this).html() + '</a></li>');
            });

            $div.append($ul);

            $(this).replaceWith($div);

            sepa_schedule.bindBanca();
        });

        $("#" + dt.idTable + "_wrapper").find("#" + dt.idTable + "_filter").find('.dropdown-toggle.set_stato').each(function () {
            var $div = $('<div class="dropdown" style="display: inline-block;"></div>');
            $(this).attr('data-toggle', 'dropdown');
            $div.html($(this).clone());

            var $ul = $('<ul class="dropdown-menu"></ul>');
            $("#template_stato_riga").find('option').each(function () {
                $ul.append('<li><a style="cursor: pointer;" data-interaction="set_stato" data-id="' + $(this).attr('value') + '">' + $(this).html() + '</a></li>');
            });

            $div.append($ul);

            $(this).replaceWith($div);

            sepa_schedule.bindStato();
        });

        $('[data-interaction=mail_status]').unbind('click').bind('click', function () {
            var error = $(this).attr('data-error');

            if ($.trim(error) != "") {
                app.warning("", error);
            } else {
                sepa_schedule.sendEmail([$(this).closest('tr').attr('data-id')]);
            }
        });
    };

    this.refreshDetails = function(){
        dt.table.draw('page');
    };

    this.refresh = function (dz, data)
    {
        app.block(0);
        dt.table.draw('page');

        if (data.response) {
            if (data.message.length > 0) {
                // ho delle informazioni da visualizzare all'utente
                var $modal = $("#modalRiepilogo");
                var $tbody = $modal.find("#riepilogoImportazione").find('tbody');

                $.each(data.message, function (i, v) {
                    $tbody.append('<tr style="background-color: ' + v.color + '"><td><i class="fa fa-' + v.icon + '"></i> ' + v.info + '</td></tr>');
                });

                $modal.modal('show');
            }
        } else {
            app.warning("", data.message);
        }
    };

    this.init = function ()
    {
        this.on('sending', function() {
            app.block(1);
        });
    };

    this.downloadZip = function ()
    {
        $.post(app.baseUrl + "/sepa_schedule_detail/zip", {ids: dt.getSelectedRows()})
            .done(function (data) {
                if (data.response) {
                    window.location.href = app.baseUrl + "/sepa_schedule_detail/download?id=" + $("#id_sepa_schedule").val()
                } else {
                    app.warning("", data.message);
                }
            })
            .fail(function (data) {
                app.error("", "Errore!");
            });
    };

    this.generaXML = function (selectedIds)
    {
        selectedIds = selectedIds || [];
        var id = $("#id_sepa_schedule").val();
        $.post(app.baseUrl + "/sepa_schedule/" + id + "/xml", {selectedIds:selectedIds})
            .done(function (data) {
                if (data.response) {
                    window.location.href = app.baseUrl + "/sepa_schedule/download?file=" + data.message;
                } else {
                    app.warning("", data.message);
                }
            })
            .fail(function (data) {
                app.error("", "Errore!");
            });
    };

    this.bindBanca = function ()
    {
        $('[data-interaction=banca]').unbind('click').bind('click', function () {
            var id_banca = $(this).attr('data-id');
            var ids = dt.getSelectedRows();

            if (ids.length == 0) {
                app.warning("", "Selezionare almeno un elemento");
                return;
            }

            app.block(1);
            $.post(app.baseUrl + "/sepa_schedule_detail/banca", {id_banca: id_banca, ids: ids})
                .done(function (data) {
                    if (data.response) {
                        app.success('', 'Banca selezionata correttemente');
                        dt.redrawPage();
                    } else {
                        app.warning('', data.message);
                    }

                    app.block(0);
                })
                .fail(function (data) {
                    app.block(0);
                    app.error("Errore!");
                });
        });
    };

    this.bindStato = function ()
    {
        $('[data-interaction=set_stato]').unbind('click').bind('click', function () {
            var stato = $(this).attr('data-id');
            var ids = dt.getSelectedRows();

            if (ids.length == 0) {
                app.warning("", "Selezionare almeno un elemento");
                return;
            }

            app.block(1);
            $.post(app.baseUrl + "/sepa_schedule_detail/stato", {stato : stato , ids: ids})
                .done(function (data) {
                    if (data.response) {
                        app.success('', 'Stato impostato correttemente');
                        dt.redrawPage();
                    } else {
                        app.warning('', data.message);
                    }

                    app.block(0);
                })
                .fail(function (data) {
                    app.block(0);
                    app.error("Errore!");
                });
        });
    };

    this.sendEmail = function (ids)
    {
        ids = ids || dt.getSelectedRows();

        if (ids.length == 0) {
            app.warning("", "Selezionare almeno un elemento");
            return;
        }

        if (ids.length == 1) {
            if (!confirm("Sei sicuro di voler inviare la busta paga all'operatore?"))
                return;
        } else {
            if (!confirm("Sei sicuro di voler inviare le buste paga agli operatori?"))
                return;
        }

        app.block(1);
        $.post(app.baseUrl + "/sepa_schedule_detail/send", {ids: ids})
            .done(function (data) {
                if (data.response) {
                    app.success("", data.message);
                } else {
                    app.warning("", data.message);
                }

                app.block(0);
            })
            .fail(function (data) {
                app.block(0);
                app.error("", "Errore!");
            });
    };

    this.updateTotalizzatoriStato = function(){
        var idSS = $("#id_sepa_schedule").val();
        if(parseInt(idSS)>0) {
            $.get("sepa_schedule/" + idSS + "/totalizzatori_stato", {}, function (data) {
                $("[data-interaction=tbl_totalizzatore_stato] [data-totalizzatore='1']").detach();
                $.each(data.message, function(index, obj){
                    var template = $("[data-interaction=tbl_totalizzatore_stato] [data-interaction=template_row]").clone();
                    template.show();
                    template.removeAttr('data-interaction');
                    template.attr("data-totalizzatore", '1');
                    template.find('[data-interaction="totalizzatore_label"]').html(obj.label);
                    template.find('[data-interaction="totalizzatore_value"]').html(obj.value);
                    template.css("background-color", obj.color);

                    $("[data-interaction=tbl_totalizzatore_stato]").append(template);
                });
            });
        }
    };

    dt.DTBeforefnCreatedCell = function (td, data, rowData, rowIndex, columnIndex)
    {
        var colTotal = configurationName == 'default' ? 11 : 10;
        if (columnIndex == colTotal)
            $(td).css({
                'font-weight': 'bold',
                'font-size': '15px'
            });

        return !(columnIndex == 8 && (rowData[8] == "Nessuna" || rowData[8] == "Completa"));
    };

    var map = {
        "Nuovo": "transparent",
        "Da pagare": "lightsalmon",
        "Pagato": "lightgreen",
        "Da non pagare": "lightcoral",
        "Da confermare": "lightgrey"
    };
    dt.afterDTfnCreatedRow = function (nRow, aData)
    {
        var colStatus = configurationName == 'default' ? 8 : 11; // 7->11
        $(nRow).css('background-color', map[aData[colStatus]]);
        if (configurationName == 'default')
            $(nRow).find('td:last-child').css('background-color', 'white');
    };
};