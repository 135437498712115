var distinta_consegna = function (dt)
{
    var distinta_consegna = this;

    this.bindEvents = function ()
    {
        $('[data-interaction=genera_consegna]').unbind('click').bind('click', function () {
            var data = $.trim($("#data_consegna").val());
            var id_autista = $.trim($("#id_autista").val());

            if (data == "") {
                app.warning("", "Selezionare una data!");
                return;
            }

            if (id_autista == "") {
                app.warning("", "Selezionare un autista!");
                return;
            }

            app.block(1);
            $.post(app.baseUrl + "/distinta_consegna", {data: data, id_autista: id_autista})
                .done(function (data) {
                    if (data.response) {
                        $("#id_distinta").val(data.message);
                        documento.idDistinataConsegna = data.message;
                        $.get(app.baseUrl + "/distinta_consegna_dettaglio", {id_subgrid: data.message}) // id_subgrid così sono compatibile con un'eventuale subgrid
                            .done(function (data) {
                                $("#distinte").html(data);
                            });
                        distinta_consegna.constructor.afterGeneraDistinta();
                    } else {
                        app.warning("", data.message);
                        $("#distinte").html("");
                        documento.idDistinataConsegna = undefined;
                        distinta_consegna.afterGeneraDistinta();
                    }

                    app.block(0);
                })
                .fail(function (data) {
                    app.block(0);
                    app.error("", "Errore AJAX!");
                    $("#distinte").html("");
                    documento.idDistinataConsegna = undefined;
                    distinta_consegna.afterGeneraDistinta();
                });
        });

        $('[data-interaction=downloadDDT]').unbind('click').bind('click', function () {
            window.location.href = app.baseUrl + "/distinta_consegna/" + $(this).attr('data-id') + "/download?ddt=1";
        });
    };

    this.bindSelectDistinta = function ()
    {
        dt.table.on('select', function (e, tbl, type, indexes) {
            var id = dt.table.row(indexes[0]).data()[0];
            $("#id_distinta").val(id);
            documento.idDistinataConsegna = id;
            $.get(app.baseUrl + "/distinta_consegna_dettaglio", {id_subgrid: id}) // id_subgrid così sono compatibile con un'eventuale subgrid
                .done(function (data) {
                    $("#distinte").html(data);
                });
            distinta_consegna.constructor.afterGeneraDistinta();
        });
    };

    this.loadOrdini = function ()
    {
        if ($("#ordini").length > 0) {
            $.get(app.baseUrl + "/documento", {dt: 'consegna', type: "ORD", stato_ordine: $("#stato_ordine_default").val()})
                .done(function (data) {
                    $("#ordini").html(data);
                });
        }
    };

    this.genera = function ()
    {
        var ids = dt.getSelectedRows();

        if (ids.length == 0) {
            app.warning("", "Selezionare almeno un elemento!");
            return;
        }

        app.block(1);
        $.post(app.baseUrl + "/distinta_consegna/" + $("#id_distinta").val() + "/genera", {ids: ids, ddt: 1})
            .done(function (data) {
                if (data.response) {
                    distinta_consegna.startInterval();
                } else {
                    app.warning("", data.message);
                }

                app.block(0);
            })
            .fail(function (data) {
                app.block(0);
                app.error("", "Errore AJAX!");
                // TODO guaradre dentro data
            });

    };

    this.svincola = function () {
        var ids = dt.getSelectedRows();

        if (ids.length == 0) {
            app.warning("", "Selezionare almeno un elemento!");
            return;
        }

        app.block(1);
        $.post(app.baseUrl + "/distinta_consegna/" + $("#id_distinta").val() + "/svincola", {ids: ids})
            .done(function (data) {
                if (data.response) {
                    app.success(data.message);
                    distinta_consegna.constructor.finishSvincola();
                } else {
                    app.warning("", data.message);
                }

                app.block(0);
            })
            .fail(function (data) {
                app.block(0);
                app.error("", "Errore AJAX!");
                // TODO guaradre dentro data
            });
    };

    this.stampa = function ()
    {
        app.block(1);
        $.post(app.baseUrl + "/distinta_consegna/" + $("#id_distinta").val() + "/genera")
            .done(function (data) {
                if (data.response) {
                    window.location.href = app.baseUrl + "/distinta_consegna/" + $("#id_distinta").val() + "/download";
                } else {
                    app.warning("", data.message);
                }

                app.block(0);
            })
            .fail(function (data) {
                app.block(0);
                app.error("", "Errore AJAX!");
            });
    };

    this.elimina = function (ids)
    {
        ids = ids || dt.getSelectedRows();
        if (ids.length == 0) {
            app.warning("", "Selezionare almeno un elemento da eliminare");
            return;
        }

        if (!confirm("Eliminare i (" + ids.length + ") elementi selezionati?"))
            return;

        app.block(1);
        $.post(app.baseUrl + "/distinta_consegna_dettaglio/get_ddt", {ids: ids})
            .done(function (data) {
                if (data.response) {
                    if (Array.isArray(data.message) && data.message.length > 0) {
                        // ho dei ddt collegati per i dettagli che voglio eliminare
                        var dettagli = "";
                        $.each(data.message, function () {
                            dettagli += this.numero + "\n";
                        });
                        if (!confirm("Ci sono DDT collegati ai dettagli selezionati. DDT numero:\n" + dettagli + "\nProcedere all'eliminazione anche dei DDT?")) {
                            app.block(0);
                            return;
                        }
                    }

                    app.block(1);
                    $.post(dt.getUrlWithParameters(dt.ajaxUrl + "&op=delete"), {ids: ids})
                        .done(function (data) {
                            if (data.response) {
                                app.success("", "Elementi eliminati");
                                dt.redrawPage();
                                dt.selected_ids = dt.selected_ids.filter(function (value) {
                                    return ids.indexOf(value) < 0;
                                });
                                dt.afterDeleteRow();
                            } else {
                                app.warning("", data.message);
                            }

                            app.block(0);
                        })
                        .fail(function (data) {
                            app.block(0);
                            app.error("", app.parseAjaxError(data));
                        });
                } else {
                    app.warning("", data.message);
                }

                app.block(0);
            })
            .fail(function () {
                app.block(0);
                app.error("", app.parseAjaxError(data));
            });
    };

    this.verificaGenerazioneDocumenti = function () {
        var type = 'DDT';
        $.post(app.baseUrl + "/distinta_consegna/verifica_coda")
            .done(function (data) {
                if (data.response) {
                    $("#" + dt.idTable + "_wrapper").find("[data-interaction='consegne_coda_stampa']").html("( " + data.message + " rimanenti )");
                    $("#" + dt.idTable + "_wrapper").find("[data-interaction=btnGeneraDDT]").removeClass("fa-print").addClass("fa-spinner fa-spin blue");
                    $("#" + dt.idTable + "_wrapper").find("[data-interaction=btnGeneraDDT]").closest('button').attr('disabled', 'disabled');
                } else {
                    $("#" + dt.idTable + "_wrapper").find("[data-interaction='consegne_coda_stampa']").html("");
                    $("#" + dt.idTable + "_wrapper").find("[data-interaction=btnGeneraDDT]").removeClass("fa-spinner fa-spin green").addClass("fa-print");
                    $("#" + dt.idTable + "_wrapper").find("[data-interaction=btnGeneraDDT]").closest('button').removeAttr('disabled');

                    // faccio il clear dell'intervallo
                    distinta_consegna.clearInterval();
                    distinta_consegna.constructor.finishGeneraDDT();
                }
            });
    };

    this.clearInterval = function () {
        // se un interval è già in esecuzione lo elimino
        if (typeof distinta_consegna.constructor.interval != "undefined")
            clearInterval(distinta_consegna.constructor.interval);
    };

    this.startInterval = function () {
        this.clearInterval();

        distinta_consegna.constructor.interval = setInterval(function () {
            distinta_consegna.verificaGenerazioneDocumenti();
        }, 10000);

        distinta_consegna.verificaGenerazioneDocumenti();
    };

    this.makeOrderable = function () {
        dt.$table.find("tbody").sortable({
            opacity: 0.6,
            cursor: 'move',
            update: function (ev, ui) {
                var ids = [];
                dt.$table.find("tbody tr").each(function (index, obj) {
                    ids.push($(obj).attr("data-id"));
                });
                $.post(app.baseUrl + "/distinta_consegna/" + $("#id_distinta").val() + "/ordina", {ids: ids}, function (data) {
                    if (data.response) {
                        dt.table.draw("page");
                    } else {
                        app.error("", data.message);
                    }
                });
            }
        });
    };
};

distinta_consegna.interval = undefined;
distinta_consegna.afterGeneraDistinta = function () {};
distinta_consegna.finishGeneraDDT = function () {};
distinta_consegna.finishSvincola = function () {};