var unita_locale = function () {

    this.bindEvents = function () {};

    /**
     * Recupera informazioni sull'unità locale di un soggetto e le restituisce alla callback
     *
     * @param id
     * @param callback
     */
    this.get = function (id, callback) {
        $.get(app.baseUrl + "/unita_locale/" + id)
            .done(function (data) {
                if (data.response) {
                    callback(data.message);
                } else {
                    app.warning("", data.message);
                }
            })
            .fail(function () {
                app.error("", "Errore AJAX!");
            });
    };

};
