var magazzino_posizionale = function () {

    var magazzino_posizionale = this;

    this.localiDisponibili = [];

    this.bindEvents = function () {

    };

    this.loadGridLocali = function () {
        $("#dislocazioneLocaliContainer").find("[data-dislocazioneLocaleid]").each(function (index, obj) {
            var id_locale = $(obj).attr("data-dislocazioneLocaleid");
            $.get("magazzino_posizionale?dt=locale_" + id_locale, {id_locale: id_locale}, function (data) {
                $(obj).html(data);
            });
        });
    };

    this.variazionePeso = function(id_lotto, id_table) {
        $.get('variazioni_lotto', {id_lotto:id_lotto}, function(data){
            $("#myModalVariazionePeso .modal-body").html(data);

            $("#myModalVariazionePeso").find('[data-dismiss=modal]').unbind('click').bind('click', function () {
                $("#" + id_table).DataTable().draw('page');
            });

            $("#myModalVariazionePeso").modal('show');
        });

    };

    this.spostaLotto = function (idMagazzinoPosizionale, idLocale) {

        app.blockUI(1);
        $.post("magazzino_posizionale/muovilotto", {
            id: idMagazzinoPosizionale,
            idLocale: idLocale
        }, function (data) {
            if (data.response) {
                $.each(aDT, function (index, obj) {
                    obj.table.draw("page");
                });
            } else {

            }
            app.blockUI(0);
        });
    };

};
