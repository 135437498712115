/**
 * Oggetto utilizzato per gestire l'ambiente ortomio
 */
var ortomio = function (dt) {

    var ortomio = this;

    this.dt_ortomio_operatori = null;

    this.tw_calendario = null;

    this.debug = 0;

    this.bindEvents = function () {

    };

    this.aOperatoriSelezionati = [];
    this.objOperatore = null; // oggettino del dom su cui si è cliccato per aprire l'elenco operatori
    this.openSelectorOperatore = function (hide, objOperatore, aIdsToHilight) {

        // elenco ID operatori da evidenziare
        aIdsToHilight = aIdsToHilight || [];
        ortomio.objOperatore = objOperatore;
        // reset elenco operatori selezionati
        ortomio.aOperatoriSelezionati = [];

        function search(force) {
            var searchString = $("#myModalOperatori").find("[data-interaction='ortomio_operatori_ricerca']").val();

            //if (!force && searchString.length < 2) return; //wasn't enter, not > 2 char

            if (searchString.length == 0) {
                $("#myModalOperatori .row_disabled").removeClass('row_disabled').addClass('row');
                $("#myModalOperatori [data-operatoreid]").attr("data-to_hide", 0);
            } else {
                $("#myModalOperatori .row").removeClass('row').addClass('row_disabled');
                $("#myModalOperatori [data-operatoreid]").attr("data-to_hide", 1);

                $("#myModalOperatori [data-operatoreid] [data-interaction=operatore_matricola]:contains('" + searchString.toUpperCase() + "')").parents("[data-operatoreid]").attr("data-to_hide", 0);
                $("#myModalOperatori [data-operatoreid] [data-interaction=operatore_nome]:contains('" + searchString.toUpperCase() + "')").parents("[data-operatoreid]").attr("data-to_hide", 0);

            }
            $("#myModalOperatori [data-operatoreid][data-to_hide='1']").hide();
            $("#myModalOperatori [data-operatoreid][data-to_hide='0']").show();

        }

        hide = hide || 0;
        if (parseInt(ortomio.debug) || $("#myModalOperatori").length == 0) {

            if (hide)
                app.blockUI(true);
            $.get("ortomio_operatore/open_selector", {}, function (data) {

                $("#myModalOperatori").detach();
                // se non esiste il modal di selezione operatori, lo creo
                var modalOp = $("#myModal").clone();
                modalOp.attr("id", "myModalOperatori");
                $("#myModal").parent().append(modalOp);

                $("#myModalOperatori").find(".modal-body").html(data);


                $("#myModalOperatori .modal-footer").detach();
                $("#myModalOperatori").find(".modal-title").detach();

                $("#myModalOperatori .modal-header").append($("#myModalOperatori").find(".modal-body").find("#nav-search"));

                $("#myModalOperatori").find(".modal-body").css("max-height", $(window).height() - $("#myModalOperatori .modal-header").height());
                $("#myModalOperatori").find(".modal-body").css("overflow-y", "auto");

                $("#myModalOperatori").find("[data-interaction=ortomio_turno_sms_operatori]").unbind("click").bind("click", function () {
                    if (ortomio.aOperatoriSelezionati.length > 0) {
                        ortomio.inviaSMSTurno(undefined, ortomio.aOperatoriSelezionati);
                    } else {
                        app.warning("Selezionare almeno un operatore");
                    }
                });

                $("#myModalOperatori").find("[data-interaction='ortomio_turno_add_operatore']").unbind("click").bind("click", function () {

                    var giornata_selezionata = parseInt(ortomio.objOperatore.parents('[data-ortomio-giornataid]').attr("data-ortomio-giornataid"));
                    var posizione_selezionata = parseInt(ortomio.objOperatore.attr("data-posizione"));
                    var numero_operatori_max = parseInt(ortomio.objOperatore.parents('[data-ortomio-giornataid]').attr("data-operatori-max"));
                    var numero_operatori_selezionati = parseInt(ortomio.objOperatore.parents('[data-ortomio-giornataid]').attr("data-operatori-selezionati"));

                    // conferma selezione operatori
                    if ((numero_operatori_selezionati != 1) && (ortomio.aOperatoriSelezionati.length) > (numero_operatori_max)) {
                        app.warning("Attenzione, puoi selezionare al massimo " + (numero_operatori_max - numero_operatori_selezionati) + " operatori per la giornata");
                        return;
                    }

                    app.blockUI(true);
                    $.post("ortomio_turno/imposta_operatori_giornata", {
                        operatori_selezionati: ortomio.aOperatoriSelezionati,
                        giornata_selezionata: giornata_selezionata,
                        posizione_selezionata: posizione_selezionata
                    }, function (data) {
                        if (!data.response) {
                            app.error(data.message);
                        } else {
                            ortomio.redrawOrtomioTurniGiornata(giornata_selezionata, data.message);
                        }
                        app.blockUI(false);
                    }).error(function () {
                        app.blockUI(false);
                    });

                });

                $("#myModalOperatori").find("[data-interaction='ortomio_operatori_ricerca']").unbind("keyup").bind("keyup", function (e) {
                    clearTimeout($.data(this, 'timer'));
                    if (e.keyCode == 13)
                        search(true);
                    else
                        $(this).data('timer', setTimeout(search, 500));
                });

                /**
                 * Funzione click su box operatore / selezione / deselezione
                 */
                $("#myModalOperatori").find("[data-operatoreid]").unbind("click").bind("click", function () {

                    var idOperatore = $(this).attr("data-operatoreid") + "";
                    var index = ortomio.aOperatoriSelezionati.indexOf(idOperatore);
                    if (index == -1) {
                        // aggiunta operatore
                        $(this).addClass('operatore_selezionato');
                        ortomio.aOperatoriSelezionati.push(idOperatore);
                    } else {
                        // rimozione operatore
                        $(this).removeClass('operatore_selezionato');
                        ortomio.aOperatoriSelezionati.splice(index, 1);
                    }

                    $("[data-interaction=ortomio_operatori_ricerca]").focus();
                });


                $("#myModalOperatori").on("shown.bs.modal", function () {
                    $("[data-interaction=ortomio_operatori_ricerca]").focus();
                });

                if (!hide)
                    $("#myModalOperatori").modal("show");

                if (hide)
                    app.blockUI(false);
            });
        } else {
            $("#myModalOperatori").on("shown.bs.modal", function () {
                $("[data-interaction=ortomio_operatori_ricerca]").focus();
            });

            ortomio.aOperatoriSelezionati = aIdsToHilight;
            $("#myModalOperatori").find("[data-operatoreid]").removeClass('operatore_selezionato');

            // evidenzio gli operatori presenti to hilight
            $.each(ortomio.aOperatoriSelezionati, function (index, id) {
                $("#myModalOperatori").find("[data-operatoreid='" + id + "']").addClass('operatore_selezionato operatore_hilight');
            });

            // TODO da sistemare gli operatori hilight in alto nella lista
            /*for (var i = 0; i < ortomio.aOperatoriSelezionati.length; i++) {
             var opNotSelected = $("#myModalOperatori").find("[data-operatoreid]:not(.operatore_selezionato):first");
             console.log(opNotSelected);
             // verifico che l'operatore hilight che voglio spostare non sia già in posizione corretta
             $("#myModalOperatori").find("[data-operatoreid='" + ortomio.aOperatoriSelezionati[i] + "']").swapWith(opNotSelected);

             }*/

            if (!hide) {
                $("#myModalOperatori").find("[data-interaction='ortomio_operatori_ricerca']").val('');
                search();
                $("#myModalOperatori").modal("show");
            }
        }

    };

    this.modificaTimesheetSelezionati = function (dt) {

        $("#myModalEditMultiTimesheet").modal("show");

        // reset campi
        $("#ortomio_multi_edit_luogo").val(0);
        $("#ortomio_multi_edit_luogo").trigger("change");
        $("#ortomio_multi_edit_prodotto").val(0);
        $("#ortomio_multi_edit_prodotto").trigger("change");
        $("#ortomio_multi_edit_attivita").val(0);
        $("#ortomio_multi_edit_attivita").trigger("change");
        $("#ortomio_multi_edit_da").val('');
        $("#ortomio_multi_edit_a").val('');

        $("#myModalEditMultiTimesheet .btn-primary").unbind("click").bind("click", function () {

            $.post(app.baseUrl + "/ortomio_timesheet/multi_edit", {
                'id_ortomio_luogo': ($.trim($("#ortomio_multi_edit_luogo").val()) != '' ? $.trim($("#ortomio_multi_edit_luogo").val()) : undefined),
                'id_ortomio_prodotto': ($.trim($("#ortomio_multi_edit_prodotto").val()) != '' ? $.trim($("#ortomio_multi_edit_prodotto").val()) : undefined),
                'id_ortomio_attivita': ($.trim($("#ortomio_multi_edit_attivita").val()) != '' ? $.trim($("#ortomio_multi_edit_attivita").val()) : undefined),
                'da': ($.trim($("#ortomio_multi_edit_da").val()) != '' ? $.trim($("#ortomio_multi_edit_da").val()) : undefined),
                'a': ($.trim($("#ortomio_multi_edit_a").val()) != '' ? $.trim($("#ortomio_multi_edit_a").val()) : undefined),
                'ids': dt.getSelectedRows()
            }, function (data) {
                if (!data.response)
                    app.error(data.message);

                $("#myModalEditMultiTimesheet .close").click();
                // dt.redrawPage();
               app.reload();
            })
        });
    };

    this.bindEventsOrtomioOperatori = function () {
        ortomio.dt_ortomio_operatori = dt;
        app.runBind();
    };

    this.ortomio_turno_copia_in_giornata = function (giornataIdDa, giornataIdA) {

        if (!confirm("Copiare la giornata?")) {
            return;
        }

        $.post("ortomio_turno/copia_giornata", {giornataIdDa: giornataIdDa, giornataIdA: giornataIdA}, function (data) {
            if (data.response) {
                ortomio.redrawOrtomioTurniGiornata(giornataIdA, data.message);
            } else {
                app.error(app.message);
            }
        });

    };

    this.ortomioTurniGotoWeek = function (luogo, settimana, anno) {
        app.blockUI(true);
        // azzero il selettore operatori
        $("#myModalOperatori").detach();
        // creazione nuova settimana turno
        $.post("ortomio_turno", {
            id_ortomio_luogo: luogo,
            settimana_turno: settimana,
            anno_turno: anno,
            muovi_in_settimana: 1
        }, function (data) {
            if (data.response) {
                app.href("ortomio_turno/" + data.message);
            } else {
                app.error(data.message);
            }
            app.blockUI(false);
        }).error(function () {
            app.blockUI(false);
        });
    };

    this.showLogSMSTurno = function (giornataId, messages) {
        messages = messages || undefined;
        $.get('ortomio_turno/log_sms', {giornataId: giornataId}, function (data) {
            $("#myModal").find(".modal-title").html('Log invio SMS per gli operatori del turno');

            $("#myModal").find(".modal-body").html(data);
            $("#myModal").find(".btn-default").html('Chiudi');
            $("#myModal").find(".btn-primary").hide();

            if (giornataId == 0) {
                var html = $("<table class='table'><tr><th>Operatore</th><td>Stato</td></tr></table>");

                $.each(messages, function (index, obj) {
                    var tr = $("<tr style='background-color: " + (obj['esito'] ? '#87B87F' : '#D15B47') + "'><th>" + obj['operatore'] + "</th><td>" + obj['message'] + "</td></tr>");
                    html.append(tr);
                });

                $("#myModal").find(".modal-body").html(html);
            }

            $("#myModal").modal("show");
        });

    };

    this.inviaSMSTurno = function (giornataId, aOperatoriId) {

        giornataId = giornataId || 0;
        aOperatoriId = aOperatoriId || [];

        app.blockUI(true);
        $.get('ortomio_turno/invia_sms', {giornataId: giornataId, aOperatoriId: aOperatoriId}, function (data) {
            $("#myModal").find(".modal-title").html('Seleziona gli operatori ed inserisci il testo dell\'sms da inviare');

            $("#myModal").find(".modal-body").html(data);
            $("#myModal").find(".btn-default").html('Chiudi');
            $("#myModal").find(".btn-primary").show();
            $("#myModal").find(".btn-primary").html('Invia');

            $("#myModal").on("shown.bs.modal", function () {
                $("#myModal").find("[data-interaction=sms_text]").focus();
            });


            $("#myModal").find(".btn-primary").unbind("click").bind("click", function () {

                var ids = [];
                $("#myModal").find('[data-operatoreid]:checked').each(function (index, obj) {
                    ids.push($(obj).attr('data-operatoreid'));
                });
                var text = $("#myModal").find("[data-interaction=sms_text]").val();

                $("#myModal .close").click();
                app.blockUI(true);
                $.post("ortomio_turno/invia_sms_send", {ids: ids, text: text, giornataId: giornataId}, function (data) {
                    if (data.response) {
                        app.success("SMS Inviati");
                        // apertura log invio
                        setTimeout(function () {
                            ortomio.showLogSMSTurno(giornataId, data.message);
                        }, 1000);
                    } else {
                        app.error(data.message);
                    }
                    app.blockUI(false);
                }).error(function () {
                    app.blockUI(false);
                });

            });

            $("#myModal").modal("show");

            app.blockUI(false);
        }).error(function () {
            app.blockUI(false);
        });

    };

    this.bindEventsOrtomioTurni = function (table) {
        table.find("[data-interaction=ortomio_turno_operatore]").unbind('click').bind('click', function () {
            var aIdsToHilight = [];
            $(this).parents('[data-interaction=data-ortomio-giornata-container]').find("[data-interaction=ortomio_turno_operatore][data-operatoreid!='0']").each(function () {
                aIdsToHilight.push($(this).attr("data-operatoreid"));
            });
            ortomio.openSelectorOperatore(undefined, $(this), aIdsToHilight);
        });

        table.find("[data-interaction=ortomio_turno_copia_turno]").unbind('click').bind('click', function () {

            var giornataId = $(this).parents("td[data-giornataid]").attr("data-giornataid");
            var giornataDestinazioneId = undefined;

            switch ($(this).attr("data-copia-turno")) {
                case 'sinistra':
                    giornataDestinazioneId = $(this).closest('td').prev('td').attr("data-giornataid") || -1;
                    ortomio.ortomio_turno_copia_in_giornata(giornataId, giornataDestinazioneId);
                    break;
                case 'destra':
                    giornataDestinazioneId = $(this).closest('td').next('td').attr("data-giornataid") || -2;
                    ortomio.ortomio_turno_copia_in_giornata(giornataId, giornataDestinazioneId);
                    break;
            }
        });

        table.find("[data-interaction=ortomio_turno_invia_sms]").unbind('click').bind('click', function () {

            var giornataId = $(this).parents("td[data-giornataid]").attr("data-giornataid");

            ortomio.inviaSMSTurno(giornataId);
        });

        table.find("[data-interaction=ortomio_turno_invia_sms_riga]").unbind('click').bind('click', function () {

            var turnoId = $(this).attr("data-ortomio-turno");
            var tipoTurnoId = $(this).attr("data-tipoturnoid");

            if (!confirm("Inviare l'SMS di notifica a tutta la riga del turno?"))
                return;

            app.blockUI(true);
            $.post("ortomio_turno/invia_sms_riga_send", {turnoId: turnoId, tipoTurnoId: tipoTurnoId}, function (data) {
                if (data.response) {
                    app.success("Invio effettuato");
                    app.reload();
                } else {
                    app.error(data.message);
                }
                app.blockUI(false);
            }).error(function () {
                app.blockUI(false);
            });
        });

        table.find("[data-interaction=ortomio_turno_log_sms]").unbind('click').bind('click', function () {

            var giornataId = $(this).parents("td[data-giornataid]").attr("data-giornataid");

            ortomio.showLogSMSTurno(giornataId);
        });

        table.find("[data-interaction=rimuovi_operatore_da_turno]").unbind('click').bind('click', function (e) {
            e.preventDefault();
            e.stopPropagation();

            if (!confirm("Rimuovere l'operatore dal turno?"))
                return;

            var giornataId = $(this).attr("data-ortomio-giornataid");
            var idOperatori = [];
            idOperatori.push($(this).attr("data-rimuovi-operatoreid"));
            ortomio.ortomioTurniEliminaOperatoriGiornata(giornataId, true, true, idOperatori);

        });

        $("[data-interaction=ortomio_turno_settimana_move]").unbind('click').bind('click', function () {

            var settimana = $(this).attr("data-settimana");
            var luogo = $(this).attr("data-idluogo");
            var anno = $(this).attr("data-anno");

            ortomio.ortomioTurniGotoWeek(luogo, settimana, anno);

        });

        $("[data-interaction=edit_orario_turno]").editable({
            type: 'text',
            title: 'Modifica orario',
            success: function (response, newValue) {
                $.post('ortomio_turno_giornata/set_orario', {
                    id: $(this).attr('data-id'),
                    value: newValue,
                    orario: $(this).attr('data-orario')
                }, function (data) {
                    if (data.response) {
                        app.success('Orario impostato');
                    } else {
                        app.error(data.message);
                    }
                });
                console.log($(this)); //update backbone model
                console.log($(this).attr('data-id')); //update backbone model
            }
        });
        $("[data-interaction=edit_orario_turno]").on('shown', function (e, editable) {
            editable.input.$input.inputmask('99:99');
        });

        $("[data-interaction=ortomio_turno_settimana_set_settimana]").unbind('change').bind('change', function () {

            var settimana = $(this).val();
            var luogo = $(this).attr("data-idluogo");
            var anno = $(this).attr("data-anno");

            ortomio.ortomioTurniGotoWeek(luogo, settimana, anno);

        });
        $("[data-interaction=ortomio_turno_settimana_set_anno]").unbind('change').bind('change', function () {

            var anno = $(this).val();
            var luogo = $(this).attr("data-idluogo");
            var settimana = $(this).attr("data-settimana");

            ortomio.ortomioTurniGotoWeek(luogo, settimana, anno);

        });
        $("[data-interaction=ortomio_turno_settimana_set_luogo]").unbind('change').bind('change', function () {

            var settimana = $(this).attr("data-settimana");
            var luogo = $(this).val();
            var anno = $(this).attr("data-anno");

            ortomio.ortomioTurniGotoWeek(luogo, settimana, anno);

        });

        table.find("[data-interaction=elimina_operatori_giornata]").unbind('click').bind('click', function () {

            if (!confirm("Eliminare gli operatori dal turno?")) {
                return;
            }

            var giornataId = $(this).attr("data-ortomio-giornataid");
            ortomio.ortomioTurniEliminaOperatoriGiornata(giornataId, true);
        });

        table.find("[data-interaction=elimina_operatori_colonna]").unbind('click').bind('click', function () {

            if (!confirm("Eliminare gli operatori?")) {
                return;
            }

            var dataTurnoRef = $(this).attr('data-dataturno-ref');
            app.blockUI(true, undefined, 0); // inserito il tempo di fadein altrimenti la chiamata syncrona bloccherebbe il caricamento del blockUI
            table.find("[data-interaction=elimina_operatori_giornata][data-dataturno-ref=" + dataTurnoRef + "]").each(function () {
                ortomio.ortomioTurniEliminaOperatoriGiornata($(this).attr("data-ortomio-giornataid"), false, true);
            });
            app.blockUI(false);
        });

        table.find("[data-interaction=elimina_operatori_riga]").unbind('click').bind('click', function () {

            if (!confirm("Eliminare gli operatori?")) {
                return;
            }

            var tipoTurnoRef = $(this).attr('data-tipoturno-ref');
            app.blockUI(true, undefined, 0); // inserito il tempo di fadein altrimenti la chiamata syncrona bloccherebbe il caricamento del blockUI
            table.find("[data-interaction=elimina_operatori_giornata][data-tipoturno-ref=" + tipoTurnoRef + "]").each(function () {
                ortomio.ortomioTurniEliminaOperatoriGiornata($(this).attr("data-ortomio-giornataid"), false, true);
            });
            app.blockUI(false);
        });

        //ortomio.ortomioTurniHideUnusedSlotOperatori();

    };

    this.ortomioTurniEliminaOperatoriGiornata = function (giornataId, blockUI, sync, idOperatori) {
        blockUI = blockUI || 0;
        sync = sync || 0;
        idOperatori = idOperatori || undefined;


        if (blockUI)
            app.blockUI(true);

        var fnz_to_call = '$.post';
        if (sync) {
            fnz_to_call = '$.post_sync';
        }

        var fnz = app.eval(fnz_to_call);

        fnz("ortomio_turno/elimina_operatori_giornata", {
            giornata_selezionata: giornataId,
            idOperatori: idOperatori
        }, function (data) {
            ortomio.redrawOrtomioTurniGiornata(giornataId, data.message);
            if (blockUI)
                app.blockUI(false);
        }).error(function () {
            if (blockUI)
                app.blockUI(false);
        });

    };

    this.redrawOrtomioTurniGiornata = function (giornataId, infoGiornata) {

        $("#myModalOperatori .close").click();

        var aOperatori = infoGiornata['operatori'];
        $("[data-giornataid=" + giornataId + "]").find("[data-interaction=edit_orario_turno][data-orario=dalle]").html(infoGiornata['dalle']);
        $("[data-giornataid=" + giornataId + "]").find("[data-interaction=edit_orario_turno][data-orario=alle]").html(infoGiornata['alle']);

        $("[data-ortomio-giornataid=" + giornataId + "]").attr("data-operatori-selezionati", lib.getObjects(aOperatori, 'id', 0, 999, 0, function (a, b) {
            return a != b;
        }).length);

        $("[data-ortomio-giornataid=" + giornataId + "]").find("[data-operatoreid]").each(function (index, obj) {
            var cur_operatore = lib.getObjects(aOperatori, 'posizione', $(obj).attr("data-posizione"));
            cur_operatore = cur_operatore[0];
            $(obj).attr("data-operatoreid", cur_operatore.id);
            $(obj).find("[data-rimuovi-operatoreid]").attr("data-rimuovi-operatoreid", cur_operatore.id);
            $(obj).find("[data-foto-operatoreid]").attr("data-foto-operatoreid", cur_operatore.id);
            $(obj).find("[data-foto-operatoreid]").attr("title", cur_operatore.title);
            $(obj).find("[data-foto-operatoreid]").attr("src", cur_operatore.foto);
            $(obj).find("[data-matricola-operatoreid]").html($.trim(cur_operatore.soprannome) ? cur_operatore.soprannome : cur_operatore.matricola);
        });

        // rebind eventi giornata
        ortomio.bindEventsOrtomioTurni($("#tblTurno"));

    };

    this.ortomioTurniHideUnusedSlotOperatori = function () {
        $("[data-interaction=ortomio_turno_operatore][data-operatoreid]").show();

        $("[data-ortomio-giornataid]").each(function (index, obj) {
            $(obj).find("[data-interaction=ortomio_turno_operatore][data-operatoreid='0']").not(':eq(0)').hide();
        });
    };

    /**
     * Redrow tabella operatori
     */
    this.constructor.callback_operatori = function () {
        dt.table.draw('page');
    };

    this.bindEventsTimework = function () {

        $("#select_all_operatori").unbind('change').bind("change", function () {

            $("#table_operatori [data-interaction=select_operatore]:visible").prop("checked", $("#select_all_operatori").is(':checked'));
        });

        $("[data-interaction=timesheet_accetta_tw_tutti]").unbind('click').bind("click", function () {
            var ortomio_operatore_ids = [];
            var datatw = $(this).attr("data-datatw");

            $("#table_operatori [data-interaction=select_operatore]").each(function () {
                if ($(this).is(':checked'))
                    ortomio_operatore_ids.push($(this).attr("data-id"));
            });
            if (ortomio_operatore_ids.length > 0) {
                app.blockUI(true, 'Adattamento orari in corso...');
                $.post("ortomio_timesheet/timework_accetta_tw_tutti", {
                    datatw: datatw,
                    ortomio_operatore_ids: ortomio_operatore_ids
                }, function (data) {
                    if (!data.response) {
                        console.error(data.message);
                        app.error("Errore durante l'elaborazione del timesheet");
                    } else {
                        app.success('Operazione effettuata');
                        app.reload();
                    }
                    app.blockUI(false);
                }).error(function () {
                    app.blockUI(false);
                    app.error("Errore durante l'operazione");
                });
            } else {
                app.warning("Selezionare almeno un operatore");
            }
        });

        $("[data-interaction=timesheet_accetta_tw]").unbind('click').bind("click", function () {

            var ortomio_operatore_id = $(this).attr("data-ortomiooperatoreid");
            var datatw = $(this).attr("data-datatw");

            $.post("ortomio_timesheet/timework_accetta_tw", {
                ortomio_operatore_id: ortomio_operatore_id,
                datatw: datatw,
                slotDuration: $(this).attr("data-slot-duration")
            }, function (data) {
                if (!data.response) {
                    app.error("Errore durante l'elaborazione del timesheet");
                } else {
                    ortomio.loadPreviewAccettaTW(data.message);
                }
            });

        });

    };

    this.loadPreviewAccettaTW = function (options) {

        $("#myModal").find(".modal-title").html("<div class='text-center'>Timesheet operatore: <b>" + options.operatore + "</b></div>");
        $("#myModal").find(".modal-body").html("<div style='width: 100%; overflow-y: auto' data-interaction='preview_accetta_tw'></div>" +
            "<hr class='col-xs-12'>" +
            "<div class='col-xs-12' data-interaction='preview_accetta_tw_dettagli'>" +
            "   <div class='col-sm-3'>" +
            "      <table class='table'><thead><tr><th colspan='2'>Riepilogo ore</th></tr></thead>" +
            "          <tbody>" +
            "               <tr><th>Ore TW</th><td data-interaction='riepilogo_ore_tw'>" + options.oreTotTW + "</td></tr>" +
            "               <tr><th>Ore OrtoMio</th><td data-interaction='riepilogo_ore_om'></td></tr>" +
            "          </tbody>" +
            "      </table>" +
            "   </div>" +
            "   <div class='col-sm-6 col-sm-offset-3'>" +
            "      <table data-interaction='containerEditEvento' style='display: none;' class='table col-xs-12'><thead><tr><th colspan='2'>Dettagli attività <!-- i data-interaction='aggiungi_attivita_timesheet' title='Aggiungi attività su OrtoMio' class='clickable fa fa-plus-circle'></i --> <i data-interaction='elimina_attivita_timesheet' title='Elimina attività da OrtoMio' class='clickable fa fa-trash red'></i></th></tr></thead>" +
            "          <tbody>" +
            "               <tr>" +
            "                   <th class='col-sm-3'>Luogo</th>" +
            "                   <td class='col-sm-9'>" +
            "                       <select data-interaction='riepilogo_luogo' data-select_edit_evento='1' data-url='ortomio_luogo/autocomplete_luogo_figlio' class='form-control autocomplete' data-length='0' data-onselected=''>" +
            "                       </select>" +
            "                   </td>" +
            "               </tr>" +
            "               <tr>" +
            "                   <th class='col-sm-3'>Prodotto</th>" +
            "                   <td class='col-sm-9'>" +
            "                       <select data-interaction='riepilogo_prodotto' data-select_edit_evento='1' data-url='ortomio_prodotto/autocomplete_prodotto' class='form-control autocomplete' data-length='0' data-onselected=''>" +
            "                       </select>" +
            "                   </td>" +
            "               </tr>" +
            "               <tr>" +
            "                   <th class='col-sm-3'>Attività</th>" +
            "                   <td class='col-sm-9'>" +
            "                       <select data-interaction='riepilogo_attivita' data-select_edit_evento='1' data-url='ortomio_attivita/autocomplete_attivita_figlia' class='form-control autocomplete' data-length='0' data-onselected=''>" +
            "                       </select>" +
            "                   </td>" +
            "               </tr>" +
            "          </tbody>" +
            "      </table>" +
            "   </div>" +
            "</div>" +
            "<div class='clearfix'></div>");
        $("#myModal").find(".btn-default").html("Annulla");
        $("#myModal").find(".btn-primary").html("Conferma modifica");

        $("#myModal").modal("show");

        app.runBind();

        setTimeout(function () {
            ortomio.tw_calendario = new timesheet_calendario();
            ortomio.tw_calendario.init(options);

            $("#myModal").find(".btn-primary").unbind("click").bind("click", function () {
                ortomio.tw_calendario.confirmEdit();
            })

        }, 500);

    };


};