var docry_evento_documento_verbale = function(){
    var docry_evento_documento_verbale = this;

    // Richiama funzione che specifica i totali del documento e lo scrive nel div relativo
    this.aggiorna_verbale = function(docry_evento,read_only = 0) {
        $.get(app.baseUrl + "/docry_evento/" + docry_evento + "/verbale?readonly="+ read_only)
            .done(function (data) {
                $("#verbale").html(data);
            })
            .fail(function (data) {
                console.error("Errore Ajax!");
                app.error("", "Errore Ajax!");
            });
    };
};