var pagamento = function (dt)
{
    var pagamento = this;

    this.bindEvents = function ()
    {
        $("#pagamenti_tab").bind('click', function () {
            if (!$(this).parent().hasClass("active"))
                dt.table.draw("page");
        });

        $('[data-interaction=calendario]').unbind('click').bind('click', function () {
            var id = $(this).attr('data-id');
            if (id == 0)
                return;

            if (!confirm("Vuoi aggiungere il pagamento allo scadenziario?"))
                return;

            app.block(1);
            $.post(app.baseUrl + "/pagamento/" + id + "/scadenziario")
                .done(function (data) {
                    if (data.response) {
                        app.success("", data.message);
                    } else {
                        app.warning("", data.message);
                    }

                    app.block(0);
                })
                .fail(function () {
                    app.block(0);
                    app.error("", "Errore!");
                });
        });
    };

    this.aggiungiPagamentoDaScadenzaDocumento = function ()
    {
       var fromWarningDiv = $(".btnAggiungiScadenza").attr("data-fromWarningDiv") || 0;
       var idDocumento = $("#idDocumento").val();
        app.block(1);
       $.post(app.baseUrl+"/documento/inserisci_in_scadenziario",{idDocumento:idDocumento})
           .done(function(data){
               if (data.response) {
                   if (fromWarningDiv) {
                       app.success("Scadenziario", "Inserimento Pagamento avvenuto con Successo");
                       $("#hideWarningPagamentoDocumento").click();
                   }
                   else
                       dt.table.draw('page');
               } else {
                   app.warning('', data.message);
               }
               app.block(0);
           })
           .fail(function (data) {
               app.block(0);
               app.error("", "Errore!");
           });

    };

    this.flagPagato = function () {
        $.get(app.baseUrl + "/documento/" + $("#idDocumento").val())
            .done(function (data) {
                if (typeof data.pagato != 'undefined') {
                    $("#pagato").prop("checked", data.pagato);
                }
            })
            .fail(function (data) {
                console.error("Errore recuperando stato pagato documento");
            })
    };

    dt.DTfnAfterDrawCallback = function (settings) {
        pagamento.bindEvents();
        pagamento.flagPagato();
    };
};