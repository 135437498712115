/**
 * Oggetto utilizzato per gestire l'ambiente sepa
 */
var sepa = function () {

    var sepa = this;

    this.bindEventsSchedule = function () {
        $('#id_sepa_company').unbind('change').bind('change', function () {
            // recupero le informazioni della sepa_company selezionata e compilo qualche campo in automatico
            app.block(1);
            $.get(app.baseUrl + "/sepa_company/" + $(this).val())
                .done(function (data) {
                    if (typeof data.banche != 'undefined' && data.banche.length > 0) {
                        $("#id_banca").html('<option value="">&nbsp;</option>');
                        $.each(data.banche, function (i, v) {
                            $("#id_banca").append('<option value="' + v.id + '">' + v.nome + '</option>');
                        });
                    }

                    app.block(0);
                })
                .fail(function () {
                    app.block(0);
                    app.error("", "Errore!");
                });
        });
    };

    this.loadBanchePerSoggetto = function(dt){
        if($("[data-interaction='soggetto_banca']").length > 0) {
            $.get("banca", {id_soggetto: $("[data-interaction='soggetto_banca']").attr("data-soggettoid")}, function(data) {
                $("[data-interaction='soggetto_banca']").html(data);
            });
        }
    };

    this.loadSepaScheduleDetails = function(dt){
        if($("[data-interaction='sepa_schedule_detail']").length > 0) {
            $.get("sepa_schedule_detail", {id_sepa_schedule: $("[data-interaction='sepa_schedule_detail']").attr("data-id")}, function(data) {
                $("[data-interaction='sepa_schedule_detail']").html(data);
            });
        }
    };


};