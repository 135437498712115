var studioLegaleCalendar = function (selector) {

    var calendar = this;

    this.objCalendar = selector;
    this.enablePopover = true;

    this.bindEvents = function () {
        calendar.initCalendario();
    };

    this.initCalendario = function () {
        /* initialize the calendar */

        calendar.objCalendar.fullCalendar({
            defaultView: 'agendaWeek',
            editable: false,
            droppable: false,
            slotDuration: "00:30:00",
            minTime: "00:00:00",
            maxTime: "24:00:00",
            header: {
                left: 'prev,next',
                center: 'title',
                right: 'agendaWeek,agendaDay'
            },
            buttonHtml: {
                prev: '<i class="ace-icon fa fa-chevron-left"></i>',
                next: '<i class="ace-icon fa fa-chevron-right"></i>'
            },
            events: {
                url: app.baseUrl + '/studio_legale_calendar/calendar_data',
                type: 'GET',
                data: {},
                error: function () {
                    app.error('Errore recuperando gli eventi del calendario');
                },
            },
            eventRender: function (event, element, a) {
                element.find('.fc-content').html(event.html);
            },
        });
    };

    calendar.bindEvents();
};
