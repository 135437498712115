var task = function(){
    var task = this;

    this.idProgetto = parseInt($("#frmProject").attr("data-id")) || 0;

    this.creaNuovoTask = function(dt){
        $.post("task", {id_project: task.idProgetto}, function (data) {
            if (data.response) {
                dt.redrawPage();
            } else {
                app.error(data.message);
            }
        });
    };
};