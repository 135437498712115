var product = function (idTable, isNew) {

    this.bindEvents = function (isNew) {
        isNew = isNew || 0;

        var idProduct = $("#" + idTable).attr('data-id');

        if (isNew == 1) {
            $.get("template_listino?idProduct=" + idProduct)
                .done(function (data) {
                    $("#listino_prodotto_div").html(data);
                })
                .fail(function () {
                    app.error("", "Errore AJAX!");
                });
        }
        $('[data-interaction=print]').unbind('click').bind('click', function () {
            var url = $(this).attr('data-url');
            var datasuffix = $(this).attr('data-suffix');

            app.block(1);
            $.post(url, {datasuffix: datasuffix})
                .done(function (data) {
                    if (data.response) {
                        window.open(data.message + "&d=1", "_blank");
                    } else {
                        app.warning("", data.message);
                    }
                    app.block(0);
                })
                .fail(function () {
                    app.block(0);
                    app.error("", "Errore AJAX!");
                });
        });
    };

    this.bindEvents(isNew);

};