var company = function () {

    var company = this;

    this.bindEvents = function () {
        var container  = $("#config_params")[0];
        var jsonEditor = new JSONEditor(container, {
            onChange: function () {
                // ad ogni cambiamento setto i valori
                $("[name=config_params]").val(JSON.stringify(jsonEditor.get()));
            }
        });

        var json = {};
        try {
            json = JSON.parse($("[name=config_params]").val());
        } catch ($e) {
        }

        var jsonBase = {
            "WSPS_API_KEY"                       : "",
            "WSPS_API_SECRET"                    : "",
            "NASCONDI_CODICE_PRODOTTO_IN_FATTURA": "1",
            "NASCONDI_SCONTO_IN_FATTURA"         : "1",
            "RICERCA_PRODOTTI_SU_CODICE"         : "1",
            "DROPBOX_AUTHORIZATION_TOKEN"        : "",
            "USE_MAGAZZINO_LITE"                 : "0",
            "DISABILITA_CONTROLLO_DISP_MAGAZZINO": "0",
            "ORDINI_NASCONDI_ALIQUOTE"           : "0",
            "PREZZO_UNITARIO_DA_SCORPORARE"      : "0"
        };

        json = $.extend({}, jsonBase, json);
        jsonEditor.set(json);

        // setto i valori iniziali
        $("[name=config_params]").val(JSON.stringify(jsonEditor.get()));

        $('[data-interaction=loginAs]').unbind('click').bind('click', function () {
            app.loginAs(this);
        });
    };

};