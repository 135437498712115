var register = function () {
    var trigger = true;

    this.bindEvents = function () {
        $('#country').trigger("change");

        // gestione cookie lingua
        $("#id_language").unbind('change').bind('change', function () {
            if(trigger){
                Cookies.set("id_language", $("#id_language").val(), {path: ''});

                location.href = location.href;
            }
            trigger = true;
        });

        if (Cookies.get("id_language")) {
            trigger = false;
            $("#id_language").val(Cookies.get("id_language")).change();
            $("#id_language").select2();
        } else {
            $("#id_language").select2();
        }

        $("#id_currency").select2();
    };

    this.bindEvents();
};