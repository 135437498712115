var contract = function(){
    var contract = this;

    this.idProgetto = parseInt($("#frmProject").attr("data-id")) || 0;

    this.creaNuovoContratto = function(dt){
        $.post("contract", {id_project: contract.idProgetto}, function (data) {
            if (data.response) {
                dt.table.draw("page");
            } else {
                app.error(data.message);
            }
        });
    };
};