var sepa_subject_account = function ()
{
    var sepa_subject_account = this;

    this.aggiornaTotaliSoggetto = function ()
    {
        var idSoggetto = $("#idSoggetto").val();
        $.get(app.baseUrl + "/soggetto/" + idSoggetto, {conto_operatore: 1})
            .done(function (data) {
                if (data.response) {
                    $("#tblSoggetto").find('tbody:first').find('tr[data-id="' + idSoggetto + '"]').find('[data-interaction=credito]').html(data.message.credito);
                    $("#tblSoggetto").find('tbody:first').find('tr[data-id="' + idSoggetto + '"]').find('[data-interaction=debito]').html(data.message.debito);
                    $("#tblSoggetto").find('tbody:first').find('tr[data-id="' + idSoggetto + '"]').find('[data-interaction=saldo]').html(data.message.saldo);
                } else {

                }
            })
            .fail(function (data) {
                app.error("", "Errore!");
            });
    }
};