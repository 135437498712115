var calendar = function (selector, visualizzazioneAdmin = 0, onlyTurni = 0, defaultAgenda = 0) {

    var calendar = this;

    this.objCalendar = selector;
    this.enablePopover = true;

    this.bindEvents = function () {
        calendar.initCalendario();
    };

    this.initCalendario = function () {
        /* initialize the calendar */

        calendar.objCalendar.fullCalendar({
            defaultView: defaultAgenda ? 'listThreeMonth' : 'agendaWeek',
            views: {
                listThreeMonth : {
                    type: 'list',
                    duration: { months: 3 },
                    buttonText: 'Agenda'
                }
            },
            editable: false,
            droppable: false,
            slotDuration: "00:15:00",
            minTime: "00:00:00",
            maxTime: "24:00:00",
            header: {
                left: 'prev,next',
                center: 'title',
                right: 'basicWeek,listThreeMonth,month,agendaWeek,agendaDay'
            },
            buttonHtml: {
                prev: '<i class="ace-icon fa fa-chevron-left"></i>',
                next: '<i class="ace-icon fa fa-chevron-right"></i>'
            },
            events: {
                url: 'docry_evento/calendario_data',
                type: 'GET',
                data:
                    function () {
                        return {
                            idSoggetto: $("#filtro_soggetto").val(),
                            esito: $("#filtro_esito").val(),
                            eseguito: $("#filtro_eseguito").val(),
                            idCategoria1: $("#filtro_categoria1").val(),
                            idCategoria2: $("#filtro_categoria2").val(),
                            idCategoria3: $("#filtro_categoria3").val(),
                            onlyTurni: onlyTurni,
                            visualizzazioneAdmin: visualizzazioneAdmin
                        };
                    },
                error: function () {
                    app.error('Errore recuperando gli eventi del calendario');
                },
            },
            eventReceive: function(event) {
                var today = new Date();
                var twoDigitMonth = today.getMonth()+1+"";
                if(twoDigitMonth.length==1)	twoDigitMonth="0" +twoDigitMonth;
                var twoDigitDate = today.getDate()+"";
                if(twoDigitDate.length==1)	twoDigitDate="0" +twoDigitDate;
                var currentDate = today.getFullYear() + "-" + twoDigitMonth + "-" + twoDigitDate;

                if(calendar.objCalendar.fullCalendar('getDate').format('YYYY-MM-DD') === currentDate) {
                    if(!confirm('Stai aggiungendo un evento in data odierna, sei sicuro di voler procedere?')) {
                        calendar.objCalendar.fullCalendar('removeEvents', event.id);
                        return;
                    }
                }

                $.post(app.baseUrl + "/event/create_from_calendar", {
                    id_subject: event.id,
                    id_project: event.resourceId,
                    data: event.start.format("YYYY-MM-DD"),
                    inizio: event.start.format("HH:mm:ss")
                })
                    .done(function (data) {
                        if(data.response) {
                            calendar.objCalendar.fullCalendar('removeEvents', event.id);
                            $.each(data.message, function(key, value) {
                                calendar.objCalendar.fullCalendar('removeEvents', key);
                                calendar.objCalendar.fullCalendar('renderEvent', value);
                            });
                        } else {
                            app.warning("", data.message);
                        }
                    })
                    .fail(function () {
                        app.error("", "Errore Ajax!");
                    });
            },
            eventRender: function (event, element, a) {
                var view = calendar.objCalendar.fullCalendar('getView');

                if (element.find('.fc-content').length > 0) {

                    if (view.type == 'basicWeek') {
                        var content = $("<span class='fc-time'></span><span class='fc-html'></span>");
                        element.find('.fc-content').html(content);
                        element.find('.fc-content').find(".fc-html").html(event.html);
                        if ($.trim(event.time) != '')
                            element.find('.fc-content').find(".fc-time").html(event.time + " ");

                    } else {
                        element.find('.fc-content').html(event.html);
                    }

                }else{
                    element.find('.fc-list-item-title').html(event.html);
                }
            },
            eventDragStart: function () {
                calendar.enablePopover = false;
            },
            eventDragStop: function (data, evento, ui, resourceId) {
                calendar.enablePopover = true;
            },
            eventClick: function (calEvent, jsEvent, view) {
                var id = ""+calEvent.id;
                var is_festivo = id.indexOf("FESTIVO_");
                if (is_festivo !== -1 || onlyTurni)
                    return;

                if (!visualizzazioneAdmin){
                    app.block(1);
                    $.get(app.baseUrl + '/docry_evento/' + calEvent.id + '/edit?readonly=1')
                        .done(function (data) {
                            app.block(0);

                            $("#modalVisualizzaEvento .modal-body").html(data);

                            $('#modalVisualizzaEvento').modal('show');

                            $('#modalVisualizzaEvento').on('hidden.bs.modal', function () {
                                calendar.objCalendar.fullCalendar('removeEvents');
                                calendar.objCalendar.fullCalendar('removeResources');
                                calendar.objCalendar.fullCalendar('refetchEvents');
                                calendar.objCalendar.fullCalendar('refetchResources');
                            });
                        })
                        .fail(function (data) {
                            app.block(0);
                            app.error("", "Errore AJAX!");
                        });
                }else {
                    if ($('#modalModifica').length > 0) {
                        app.block(1);
                        $.get(app.baseUrl + '/docry_evento/' + calEvent.id + '/edit?modal=2')
                            .done(function (data) {
                                app.block(0);

                                $("#modalModifica .modal-body").html(data);

                                $('#modalModifica').modal('show');

                                if ($('#modalModifica [data-interaction="modal-save"]').length > 0) {
                                    $('#modalModifica [data-interaction="modal-save"]').unbind('click').bind('click', function () {

                                        $("#frmDocryEvento").find("[data-modal-docry-evento]").removeAttr('data-reload');

                                        $("#frmDocryEvento").find("[data-modal-docry-evento]").trigger('click');
                                    });
                                }

                                $('#modalModifica [data-interaction="modal-delete"]').unbind('click').bind('click', function () {
                                    if (!confirm('Sei sicuro di voler eliminare questo elemento?')) {
                                        return;
                                    }

                                    $.delete(app.baseUrl + "/docry_evento/" + calEvent.id)
                                        .done(function (data) {
                                            if (data.response) {
                                                calendar.objCalendar.fullCalendar('removeEvents', calEvent.id);
                                                $("#modalModifica  [data-dismiss=modal]").trigger('click');
                                            } else {
                                                app.warning("", data.message);
                                            }
                                        })
                                        .fail(function () {
                                            app.error("", "Errore Ajax!");
                                        });
                                });

                                $('#modalModifica').on('hidden.bs.modal', function () {
                                    calendar.objCalendar.fullCalendar('removeEvents');
                                    calendar.objCalendar.fullCalendar('removeResources');
                                    calendar.objCalendar.fullCalendar('refetchEvents');
                                    calendar.objCalendar.fullCalendar('refetchResources');
                                });
                            })
                            .fail(function (data) {
                                app.block(0);
                                app.error("", "Errore AJAX!");
                            });
                    }else
                        window.open(app.baseUrl + '/docry_evento/' + calEvent.id +'/edit', "_blank");
                }
            }
        });
    };
    calendar.bindEvents();
};
