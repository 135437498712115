var contract_detail = function(){
    var contract_detail = this;

    this.creaNuovoContrattoDettaglio = function(dt, id_contract){
        $.post("contract_detail", {id_contract: id_contract}, function (data) {
            if (data.response) {
                dt.table.draw("page");
            } else {
                app.error(data.message);
            }
        });
    };
};