var soggetto = function (id_soggetto, dt) {

    var soggetto = this;

    this.dt = dt;
    this.id_soggetto = id_soggetto;

    this.bindEventsSoggettoAllegato = function () {
        $('[data-interaction=mail_status]').unbind('click').bind('click', function () {
            var error = $(this).attr('data-error');

            if ($.trim(error) != "") {
                app.warning("", error);
            } else {
                soggetto.sendSoggettoAllegatoEmail([$(this).closest('tr').attr('data-id')]);
            }
        });


        setTimeout(function(){
            $("#" + dt.idTable + "_wrapper").find("#" + dt.idTable + "_filter").find('.dropdown-toggle.set_tipo').each(function () {
                var $div = $('<div class="dropdown" style="display: inline-block;"></div>');
                $(this).attr('data-toggle', 'dropdown');
                $div.html($(this).clone());
                var $ul = $('<ul class="dropdown-menu"></ul>');
                $("#ul_tipo_documento").find('option').each(function () {
                    var value = $(this).attr('value');
                    var li = $('<li><a style="cursor: pointer;" data-interaction="set_tipo" data-id="' + value + '">' + $(this).html() + '</a></li>');

                    li.unbind('click').bind('click', function () {
                        var ids = dt.getSelectedRows();

                        if (ids.length == 0) {
                            app.warning("", "Selezionare almeno un elemento");
                            return;
                        }

                        app.block(1);
                        $.post(app.baseUrl + "/soggetto_allegato/set_tipo", {tipo: value, ids: ids})
                            .done(function (data) {
                                if (data.response) {
                                    app.success('', 'Tipo impostato correttamente');
                                    dt.redrawPage();
                                } else {
                                    app.warning('', data.message);
                                }

                                app.block(0);
                            })
                            .fail(function (data) {
                                app.block(0);
                                app.error("Errore!");
                            });
                    });

                    $ul.append(li);

                });

                $div.append($ul);

                $(this).replaceWith($div);
            });
        }, 500);

    };

    this.sendSoggettoAllegatoEmail = function (ids) {
            ids = ids || dtSoggettoAllegato.getSelectedRows();

            if (ids.length == 0) {
                app.warning("", "Selezionare almeno un elemento");
                return;
            }

            if (ids.length == 1) {
                if (!confirm("Sei sicuro di voler inviare l'allegato all'operatore?"))
                    return;
            } else {
                if (!confirm("Sei sicuro di voler inviare gli allegati agli operatori?"))
                    return;
            }

            app.block(1);
            $.post(app.baseUrl + "/soggetto_allegato/send", {ids: ids})
                .done(function (data) {
                    if (data.response) {
                        app.success("", data.message);
                    } else {
                        app.warning("", data.message);
                    }

                    app.block(0);
                })
                .fail(function (data) {
                    app.block(0);
                    app.error("", "Errore!");
                });
    };

    this.bindEvents = function () {
        $("[data-interaction=impostaListinoBase]").unbind("click").bind("click", function () {
            var id_listino = $("#id_listino").val() || 0;
            if (id_listino > 0) {
                soggetto.impostaListinoBase(id_listino);
            } else {
                app.error("Selezionare un listino da impostare");

            }
        });

        $("[data-interaction=impostaScontoListino]").unbind("click").bind("click", function () {
            var sconto = $("#sconto_testata").val();
            var applica = $("#applica_sconto").is(':checked') ? 1 : 0;
            var id = $(this).attr('data-id') || 0;

            app.block(1);
            $.put(app.baseUrl + "/listino/" + id, {sconto_testata: sconto, applica_sconto: applica, dt: 1})
                .done(function (data) {
                    if (data.response) {
                        app.success("", "Sconto impostato correttemente");
                    } else {
                        app.warning("", data.message);
                    }
                    app.block(0);
                })
                .fail(function (data) {
                    app.block(0);
                    var error = "Errore!";
                    if (typeof data.status != 'undefined' &&
                        data.status == 422 &&
                        typeof data.responseJSON == 'object' &&
                        typeof data.responseJSON.value != 'undefined' &&
                        data.responseJSON.value.length > 0
                    ) {
                        error = data.responseJSON.value[0];
                    }
                    app.error("", error);
                })
        });

        // check CF
        $('[data-interaction="cf"]').unbind('change').bind('change', function () {
            var cf = $(this).val().trim();
            var self = this;

            $('#spinner_cf').hide();

            $(self).css('background-color', 'transparent');
            $('#spinner_cf').show();
            $.post(app.baseUrl + '/soggetto/checkCF', {cf: cf})
                .success(function (data) {
                    if (data.response) {
                        $(self).css('background-color', 'lightgreen');
                    } else {
                        $(self).css('background-color', 'lightcoral');

                    }
                    $('#spinner_cf').hide();
                })
                .error(function () {
                    // TODO
                    $(self).css('background-color', 'lightcoral');
                    $('#spinner_cf').hide();
                });
        });

        // check VAT
        $('[data-interaction="vat"]').unbind('change').bind('change', function () {
            var vat = $(this).val().trim();
            var self = this;

            $('#spinner').hide();

            $(self).css('background-color', 'transparent');
            $('#spinner').show();
            $.post(app.baseUrl + '/soggetto/checkVat', {vat: vat})
                .success(function (data) {
                    if (data.response) {
                        if (data.message.name)
                            $('[data-interaction="company_name"]').val(data.message.name);
                        //if (data.message.full_address) $('[data-interaction="full_address"]').val(data.message.full_address);
                        $(self).css('background-color', 'lightgreen');
                    } else {
                        $(self).css('background-color', 'lightcoral');

                    }
                    $('#spinner').hide();
                })
                .error(function () {
                    // TODO
                    $(self).css('background-color', 'lightcoral');
                    $('#spinner').hide();
                });
        });
    };

    this.impostaListinoBase = function (id_listino) {
        $.post(app.baseUrl + "/listino/assegna_a_soggetto", {
            id_listino: id_listino,
            id_soggetto: soggetto.id_soggetto
        }, function (data) {
            if (data.response) {
                app.reload();
            } else {
                app.error(data.message);
            }
        });
    };

    /**
     * Recupera informazioni sul soggetto e le restituisce alla callback
     *
     * @param id
     * @param callback
     */
    this.get = function (id, callback) {
        if ($.trim(id) != '') {
            $.get(app.baseUrl + '/soggetto/' + id)
                .done(function (data) {
                    if (data.response) {
                        callback(data.message);
                    } else {
                        app.warning("", data.message);
                    }
                })
                .fail(function () {
                    app.error("", "Errore AJAX!");
                });
        }
    };

};