var erp_project = {
    init: function () {

    }
};
var google_document_picker = {
    // The Browser API key obtained from the Google Developers Console.
    developerKey: 'AIzaSyDG9pPpkz2Y0BgdXEDeI7AWbTP9_5uAm2Y',

    // The Client ID obtained from the Google Developers Console. Replace with your own Client ID.
    clientId: "90881498862-51d1lc047o6a9o37ipjafrcfq3hni5lu.apps.googleusercontent.com",            //   VPgmxFLw4130UVub3YPpD8CE
    appId: "90881498862",            //   VPgmxFLw4130UVub3YPpD8CE

    // Scope to use to access user's photos.
    scope: ['https://www.googleapis.com/auth/drive'],

    pickerApiLoaded: false,
    oauthToken: null,
    current_operation_reference: null,
    init: function() {

    },
    // Use the API Loader script to load google.picker and gapi.auth.
    onApiLoad: function() {
        gapi.load('auth', {'callback': google_document_picker.onAuthApiLoad});
        gapi.load('picker', {'callback': google_document_picker.onPickerApiLoad});
    },
    onAuthApiLoad: function() {
        window.gapi.auth.authorize(
            {
                'client_id': google_document_picker.clientId,
                'scope': google_document_picker.scope,
                'immediate': false
            },

            google_document_picker.handleAuthResult);
    },
    onPickerApiLoad: function() {
        google_document_picker.pickerApiLoaded = true;
        //google_document_picker.createPicker();
    },
    handleAuthResult: function (authResult) {
        if (authResult && !authResult.error) {
            google_document_picker.oauthToken = authResult.access_token;
            //google_document_picker.createPicker();
        }
    },
    createPicker: function (callback, reference) {     // Create and render a Picker object for picking user Photos.
        callback = callback || google_document_picker.pickerCallback;
        reference = reference || 0;

        google_document_picker.current_operation_reference = reference;

        if (google_document_picker.pickerApiLoaded && google_document_picker.oauthToken) {
            var view = new google.picker.View(google.picker.ViewId.DOCS);

            //view.setMimeTypes("image/png,image/jpeg,image/jpg");

            var picker = new google.picker.PickerBuilder()
                .enableFeature(google.picker.Feature.NAV_HIDDEN)
                .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
                .setAppId(google_document_picker.appId)
                .setOAuthToken(google_document_picker.oauthToken)
                .addView(view)
                .addView(new google.picker.DocsUploadView())
                .setDeveloperKey(google_document_picker.developerKey)
                .setCallback(callback)
                .build();
            picker.setVisible(true);
        }
    },
    pickerCallback: function (data) {   // A simple callback implementation.
        var url = 'nothing';
        if (data[google.picker.Response.ACTION] == google.picker.Action.PICKED) {
            var doc = data[google.picker.Response.DOCUMENTS][0];
            url = doc[google.picker.Document.URL];

            console.log(url);
        }

        document.getElementById('result').innerHTML = 'You picked: ' + url;
    }
};

function google_document_picker_onApiLoad() {
    google_document_picker.onApiLoad();
}