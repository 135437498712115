var variable = function ()
{

    this.nuovo = function (dt) {
        app.block(1);
        $.post(dt.baseUrl)
            .done(function (data) {
                if (data.response) {
                    dt.table.draw('page');
                } else {
                    app.warning("", data.message);
                }
                app.block(0);
            })
            .fail(function () {
                app.block(0);
                app.error("", "Errore AJAX!");
            });
    }

};