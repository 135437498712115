var geonames = function (geonameUrl) {
    var geonames = this;

    if (geonameUrl)
        app.geonameUrl = geonameUrl;

    this.selectedProvince = "";

    this.bindEvents = function () {

        // evento change non select2:select per autocomplete browser es: Chrome
        $('[data-geoname=country]').unbind('change').bind('change', function () {
            $(this).trigger("select2:select");
        });

        $("[data-geoname=country]").unbind("select2:select").bind("select2:select", function () {
            $("[data-geoname=province]").prop("disabled", true);
            $("[data-geoname=city]").prop("disabled", true);

            var val = $(this).val();

            if (typeof val != "undefined" && val != null && val != 0 && val != "") {
                $("[data-geoname=province]").prop("disabled", false);
            }

            $("[data-geoname=province]").val(0).trigger("change");
            $("[data-geoname=city]").val(0).trigger("change");
            geonames.selectedProvince = "";
        }).trigger("select2:select");

        $("[data-geoname=province]").unbind("select2:select").bind("select2:select", function () {
            if (!$(this).prop("disabled")) {
                var val = $(this).val();
                if (typeof val != "undefined" && val != null && val != 0 && val != "") {
                    $("[data-geoname=city]").prop("disabled", false);
                }

                $("[data-geoname=city]").val(0).trigger("change");
            }
        }).trigger("select2:select");


        $("[data-geoname=country]").select2({
            ajax: {
                url: app.geonameUrl + "/country",
                dataType: 'json',
                delay: 250,
                cache: true,
                data: function (params) {
                    return {
                        search: params.term // search term
                    };
                },
                processResults: function (data, params) {
                    params.page = params.page || 1;
                    return {
                        results: data.items
                    };
                }
            },
            tags: true,
            escapeMarkup: function (markup) {
                return markup;
            },
            templateResult: function (data) {
                return data.text;
            },
            templateSelection: function (data) {
                $("select[data-geoname=country]").find(":selected").attr('data-geoname-code', data.isoAlpha2);
                return data.text;
            }
        });

        $("[data-geoname=province]").select2({
            ajax: {
                url: app.geonameUrl + "/province",
                dataType: 'json',
                delay: 250,
                cache: true,
                data: function (params) {
                    return {
                        search: params.term, // search term
                        code: $("select[data-geoname=country]").find(":selected").attr('data-geoname-code') || $("select[data-geoname=country]").val()
                    };
                },
                processResults: function (data, params) {
                    params.page = params.page || 1;
                    return {
                        results: data.items
                    };
                }
            },
            tags: true,
            escapeMarkup: function (markup) {
                return markup;
            },
            templateResult: function (data) {
                return data.text;
            },
            templateSelection: function (data) {
                geonames.selectedProvince = data.admin2;
                return data.text;
            }
        });

        $("[data-geoname=city]").select2({
            ajax: {
                url: app.geonameUrl + "/city",
                dataType: 'json',
                delay: 250,
                cache: true,
                data: function (params) {
                    return {
                        search: params.term, // search term
                        prov: geonames.selectedProvince || $("select[data-geoname=province]").val(),
                        code: $("select[data-geoname=country]").find(":selected").attr('data-geoname-code') || $("select[data-geoname=country]").val()
                    };
                },
                processResults: function (data, params) {
                    params.page = params.page || 1;
                    return {
                        results: data.items
                    };
                }
            },
            tags: true,
            escapeMarkup: function (markup) {
                return markup;
            },
            templateResult: function (data) {
                return data.text;
            },
            templateSelection: function (data) {
                return data.text;
            }
        });

        // fix per chrome select2
        if ($("[data-geoname=city]").find("option[selected]").length > 0)
            $("[data-geoname=city]").val($("[data-geoname=city]").find("option[selected]").val()).trigger("change");
        if ($("[data-geoname=province]").find("option[selected]").length > 0)
            $("[data-geoname=province]").val($("[data-geoname=province]").find("option[selected]").val()).trigger("change");

    };

    this.bindEvents();
};
