/**
 * Oggetto utilizzato per gestire il calendario del timesheet timework
 */
var timesheet_calendario = function () {

    var timesheet_calendario = this;

    // riferimento all'oggetto calendario
    this.objCalendario = null;

    this.editedEvents = [];

    this.options = null; // opzioni del calendario

    /**
     * Inizializza il calendario mostrando di un colore differente ogni
     * @param options
     */
    this.init = function (options) {
        timesheet_calendario.options = options;
        timesheet_calendario.editedEvents = lib.getObjects(options.events, 'id', -1, 9999, 0, function (a, b) {
            return a != b;
        });
        console.log(timesheet_calendario.options.slotDuration);
        timesheet_calendario.aggiornaDettagli();
        timesheet_calendario.objCalendario = $('[data-interaction=preview_accetta_tw]').fullCalendar({
            defaultView: 'timelineDay',
            schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
            now: options.now,
            editable: true,
            height: 'auto',
            slotWidth: 10,// pixel per ogni colonna
            //aspectRatio: 9, // inutile se impostata height
            scrollTime: options.scrollTime || '08:00',
            minTime: '00:00',
            header: {
                left: '', //'today prev,next',
                center: 'title',
                right: ''//'timelineDay,timelineTenDay,timelineMonth,timelineYear'
            },
            views: {
                timelineDay: {
                    buttonText: 'Giorno',
                    slotDuration: timesheet_calendario.options.slotDuration
                }
            },
            resources: options.resources,
            events: options.events,
            navLinks: true,
            resourceAreaWidth: '10%',
            resourceLabelText: 'Attività',
            eventClick: function (calEvent, jsEvent, view) {
                if (calEvent.editable) {
                    timesheet_calendario.apriDettagliEvento(calEvent);
                }
            },
            dayClick: function (date, jsEvent, view) {
                var timeStart = date.format("HH:mm:ss");
                timesheet_calendario.addEvento(timeStart, lib.timeToHHMMSS(lib.timeSum(timeStart, '02:00:00')));
            },
            eventDrop: function (event, delta, revertFunc) {
                timesheet_calendario.editEvent(event);
            },
            eventResize: function (event, delta, revertFunc) {
                timesheet_calendario.editEvent(event);
            }
        });

        $("[data-interaction='aggiungi_attivita_timesheet']").unbind("click").bind("click", function () {
            timesheet_calendario.addEvento();
        });
        $("[data-interaction='elimina_attivita_timesheet']").unbind("click").bind("click", function () {
            var id_editing = $(this).attr('id_editing');
            timesheet_calendario.delEvento(id_editing);
        });

    };

    this.addEvento = function(start, end){
        var evento = {
            id_editing: Math.random(),
            editable: true,
            id: "___" + Math.random(),
            start: start || '12:30:00',
            end: end || '13:30:00',
            title: 'Nuova attività',
            idLuogo: 0,
            idProdotto: 0,
            idAttivita: 0,
            operatoreId: timesheet_calendario.options.operatoreId,
            data: timesheet_calendario.options.now,
            labelLuogo: "",
            labelProdotto: "",
            labelAttivita: "",
            resourceId: 'C'
        };

        timesheet_calendario.objCalendario.fullCalendar('renderEvent', evento);
        timesheet_calendario.editedEvents.push(evento);
        timesheet_calendario.aggiornaDettagli();

        timesheet_calendario.apriDettagliEvento(evento);
        $("[data-interaction='containerEditEvento']").show();
    };

    this.delEvento = function(id_editing){
        if(typeof id_editing == 'undefined' || $.trim(id_editing)==''){
            app.warning("Selezionare un elemento da eliminare");
            return;
        }

        if(!confirm("Eliminare l'elemento selezionato?"))
            return;

        var objs = lib.getObjects(timesheet_calendario.editedEvents, 'id_editing', id_editing);
        if (objs.length > 0) {
            var obj = objs[0];
            timesheet_calendario.objCalendario.fullCalendar('removeEvents',obj.id);

            // imposto il deleted dell'oggetto, in questo modo verrà in viato al controller, ma successivamente eliminato da timesheet se esistente
            obj.deleted = 1;

            $("[data-interaction='preview_accetta_tw_dettagli'] [data-select_edit_evento='1']").attr("data-id_editing", 0).html("<option value='0'></option>");
            $("[data-interaction='preview_accetta_tw_dettagli'] [data-interaction=elimina_attivita_timesheet]").attr("id_editing", 0);

            $("[data-interaction='containerEditEvento']").hide();

            timesheet_calendario.aggiornaDettagli();
        }
    };

    this.apriDettagliEvento = function (event) {

        $("[data-interaction='containerEditEvento']").show();

        // imposto l'id dell'elemento per la fase di eliminazione evento
        $("[data-interaction='preview_accetta_tw_dettagli'] [data-interaction=elimina_attivita_timesheet]").attr("id_editing", event.id_editing);

        $("[data-interaction='preview_accetta_tw_dettagli'] [data-interaction='riepilogo_luogo']").attr("data-id_editing", event.id_editing).html("<option value='" + event.idLuogo + "'>" + event.labelLuogo + "</option>").show();
        $("[data-interaction='preview_accetta_tw_dettagli'] [data-interaction='riepilogo_prodotto']").attr("data-id_editing", event.id_editing).html("<option value='" + event.idProdotto + "'>" + event.labelProdotto + "</option>").show();
        $("[data-interaction='preview_accetta_tw_dettagli'] [data-interaction='riepilogo_attivita']").attr("data-id_editing", event.id_editing).html("<option value='" + event.idAttivita + "'>" + event.labelAttivita + "</option>").show();
        $("[data-interaction='preview_accetta_tw_dettagli'] [data-select_edit_evento='1']").unbind("change").bind("change", function () {
            var objs = lib.getObjects(timesheet_calendario.editedEvents, 'id_editing', event.id_editing);
            if (objs.length > 0) {
                var obj = objs[0];
                switch ($(this).attr("data-interaction")) {
                    case 'riepilogo_luogo':
                        obj.idLuogo = $(this).val();
                        obj.labelLuogo = $(this).find("option:selected").text();
                        break;
                    case 'riepilogo_prodotto':
                        obj.idProdotto = $(this).val();
                        obj.labelProdotto = $(this).find("option:selected").text();
                        break;
                    case 'riepilogo_attivita':
                        obj.idAttivita = $(this).val();
                        obj.labelAttivita = $(this).find("option:selected").text();
                        break;
                }
                obj.title = obj.labelLuogo + " - " + obj.labelProdotto + " - " + obj.labelAttivita;

                console.log($(this).attr('data-id_editing'));

                var _eventiCalendario = timesheet_calendario.objCalendario.fullCalendar('clientEvents');
                var eventiCalendario = lib.getObjects(_eventiCalendario, 'id_editing', $(this).attr('data-id_editing'), 3);
                if (eventiCalendario.length > 0) {
                    var eventoCalendario = eventiCalendario[0];
                    eventoCalendario.title = obj.title;
                    eventoCalendario.idLuogo = obj.idLuogo;
                    eventoCalendario.labelLuogo = obj.labelLuogo;
                    eventoCalendario.idProdotto = obj.idProdotto;
                    eventoCalendario.labelProdotto = obj.labelProdotto;
                    eventoCalendario.idAttivita = obj.idAttivita;
                    eventoCalendario.labelAttivita = obj.labelAttivita;

                    timesheet_calendario.objCalendario.fullCalendar('updateEvent', eventoCalendario);
                }
            }
        });
    };

    this.confirmEdit = function () {
        $.post("ortomio_timesheet/update_timesheet_record", {events: timesheet_calendario.editedEvents}, function (data) {
            if (!data.response) {
                app.error(data.message);
            } else {
                $("#myModal .close").click();
            }
        });
    };

    this.aggiornaDettagli = function () {
        // aggiornamento del pannello dettagli sotto il calendatio
        var totSecondi = 0;
        $.each(timesheet_calendario.editedEvents, function (index, obj) {
            if(typeof obj.deleted == 'undefined' ) // se l'elemento non è stato eliminato
                totSecondi += lib.timeSub(obj.end, obj.start);
        });

        $("[data-interaction='preview_accetta_tw_dettagli'] [data-interaction='riepilogo_ore_om']").html(lib.timeToHHMMSS(totSecondi, 'HHMM'));
        if ($("[data-interaction='preview_accetta_tw_dettagli'] [data-interaction='riepilogo_ore_tw']").html() == lib.timeToHHMMSS(totSecondi, 'HHMM')) {
            $("[data-interaction='preview_accetta_tw_dettagli'] [data-interaction='riepilogo_ore_om']").parent().css('background-color', 'green');
        } else {
            $("[data-interaction='preview_accetta_tw_dettagli'] [data-interaction='riepilogo_ore_om']").parent().css('background-color', 'lightcoral');
        }
    };

    this.editEvent = function (event) {
        var objs = lib.getObjects(timesheet_calendario.editedEvents, 'id', event.id);

        if (objs.length > 0 && typeof objs[0] != 'undefined') {
            obj = objs[0];
        } else {
            obj = {id: event.id, start: null, end: null};
            timesheet_calendario.editedEvents.push(obj);
        }

        obj.start = event.start.format("HH:mm:ss");
        obj.end = event.end.format("HH:mm:ss");

        timesheet_calendario.aggiornaDettagli();

    };
};