/**
 * Oggetto utilizzato per gestire l'ambiente contractor
 */
var contractor = function (dt) {

    var contractor = this;

    this.report = function (obj, data) {
        $("#myModal .modal-title").html("Riepilogo Importazione");
        $("#myModal .btn-primary").hide();
        $("#myModal .modal-body").html("<ul></ul>");
        $.each(data.message, function (index, obj) {
            $("#myModal .modal-body ul").append("<li>" + obj + "</li>")
        });
        $("#myModal").modal("show");
    };

    this.showXmlError = function (message) {
        $("#myModal .modal-title").html("Errori nella produzione del file XML");
        $("#myModal .btn-primary").hide();
        $("#myModal .modal-body").html("<ul data-interact='xml_file_errors_selectors' class='red'></ul><div data-interact='xml_file_errors'></div>");

        $('[data-interact=xml_file_errors]').load(app.baseUrl + '/contractor_contratto/unilav_xml_errors?reference=' + message.reference);

        $.each(message.errors, function (index, obj) {
            $("#myModal .modal-body ul").append("<li class='clickable' data-xml-line-seek='" + obj.line + "'>" + obj.message.replace('{http://servizi.lavoro.gov.it/unilav}', '') + "</li>")
        });

        $('[data-xml-line-seek]').on('click', function () {
            var scrollTo = $('[data-xml-line=' + $(this).attr('data-xml-line-seek') + ']');
            var myContainer = $("[data-interact='xml_file_errors']");

            $("[data-xml-line]").css('background-color', 'white');
            scrollTo.css('background-color', '#EEE');

            myContainer.animate({
                scrollTop: scrollTo.offset().top - myContainer.offset().top + myContainer.scrollTop()
            });
        });

        $("#myModal").modal("show");
    };

    this.download = function (ids) {
        $.post(dt.baseUrl + "/zip_files", {ids: ids}, function (data) {
            if (data.response) {
                window.location.href = dt.baseUrl + "/download";
            } else {
                app.error(data.message);
            }
        });
    };

    this.verifyStampeInCoda = function () {
        $.get(dt.baseUrl + "/verifica_coda", {}, function (data) {
            if (data.response) {
                $("#" + dt.idTable + "_wrapper").find("[data-interaction='contractor_coda_stampa']").html("( " + data.message + " rimanenti )");
                $("#" + dt.idTable + "_wrapper").find("[data-interaction='contractor_coda_stampa']").parent().find("[data-interaction=btnGeneraDocumenti]").removeClass("fa-print").addClass("fa-spinner fa-spin green");
            } else {
                $("#" + dt.idTable + "_wrapper").find("[data-interaction='contractor_coda_stampa']").html("");
                $("#" + dt.idTable + "_wrapper").find("[data-interaction='contractor_coda_stampa']").parent().find("[data-interaction=btnGeneraDocumenti]").removeClass("fa-spinner fa-spin green").addClass("fa-print");

                // faccio il clear dell'intervallo
                contractor.clearInterval();
            }

            if (typeof dt != 'undefined')
                dt.table.draw('page');
        });
    };

    this.stampa = function (ids) {

        if (ids.length == 1) {
            app.blockUI(1, "Generazione documento in corso...");
        }

        $.post(dt.baseUrl + "/stampa", {ids: ids}, function (data) {
            if (data.response) {
                if (ids.length == 1) {
                    app.blockUI(0);
                }
                contractor.startInterval();
            } else {
                app.error(data.message);
            }
        });
    };

    this.bindEvents = function (types) {
        $("#" + dt.idTable + "_wrapper").find("#" + dt.idTable + "_filter").find('.dropdown-toggle').each(function () {
            var $div = $('<div class="dropdown" style="display: inline-block;"></div>');
            $(this).attr('data-toggle', 'dropdown');
            $div.html($(this).clone());

            var $ul = $('<ul class="dropdown-menu"></ul>');
            $.each(types, function (i, v) {
                if(typeof v != 'undefined')
                    $ul.append('<li><a style="cursor: pointer;" data-interaction="xml" data-type="' + i + '">' + v + '</a></li>');
            });

            $div.append($ul);

            $(this).replaceWith($div);

            contractor.bindXML(dt);
        });

        $("[data-interaction=btn_contratti_attivi_nel_mese]").unbind("click").bind("click", function () {
            window.location.href = app.baseUrl + "/#contractor_contratto?contratti_attivi_in_mese=" + $('#contratti_attivi_nel_mese').val();
        });

        $("[data-interaction='genera_contratto']").unbind("click").bind("click", function () {
            var id = $(this).attr("data-id");
            contractor.stampa([id], dt);
        });

        $('[data-interaction=download]').unbind('click').bind('click', function () {
            var id = $(this).attr('data-id');

            contractor.download([id]);
        });
    };

    this.bindXML = function () {
        $('[data-interaction=xml]').unbind('click').bind('click', function () {
            var ids = dt.getSelectedRows();
            if (ids.length == 0) {
                alert("Devi selezionare almeno un contratto!");
                return;
            }

            var type = $(this).attr('data-type');

            app.block(1);
            $.post(dt.baseUrl + '/xml', {ids: ids, type: type})
                .done(function (data) {
                    if (data.response) {
                        window.location.href = dt.baseUrl + "/download?type=1";
                    } else {
                        contractor.showXmlError(data.message);

                        //app.warning("", data.message);
                    }

                    app.block(0);
                })
                .fail(function (data) {
                    app.block(0);
                    app.error(data);
                });
        });
    };

    this.loadSediOperative = function (idSoggetto) {
        if ($("[data-interaction=sediOperative]").length) {
            $.get("unita_locale", {idSoggetto: idSoggetto, dt: 'ortogranda'}, function (data) {
                $("[data-interaction=sediOperative]").html(data);
            });
        }
    };

    this.loadSoggettiACarico = function () {
        if ($("[data-interaction=soggetti_a_carico]").length) {
            $.get("contractor_soggetto_a_carico", {id_contractor_operatore: $("[data-interaction=soggetti_a_carico]").attr("data-contractoroperatoreid")}, function (data) {
                $("[data-interaction=soggetti_a_carico]").html(data);
            });
        }
    };

    this.loadTimesheetTemplate = function () {
        if ($("[data-interaction=timesheet_template]").length) {
            $.get("ortomio_timesheet_template", {
                dt: 'ortomio_timesheet_template',
                idOrtomioOperatore: $("[data-interaction=timesheet_template]").attr("data-ortomiooperatoreid")
            }, function (data) {
                $("[data-interaction=timesheet_template]").html(data);

                $("[data-interaction=copiaTemplateDaOperatore]").unbind("click").bind("click", function () {
                    var idOrtomioOperatore = $("[data-interaction=elencoOperatoriTemplate]").val();
                    if (!idOrtomioOperatore) {
                        app.warning("Selezionare un operatore valido da cui copiare il template");
                        return;
                    }

                    if (confirm("Copiare il template dell'operatore selezionato ?")) {
                        var idOrtomioOperatoreDestinatario = $(this).attr("data-idortomiooperatoredestinatario");
                        $.post("ortomio_timesheet_template/copia_template", {idOrtomioOperatore: idOrtomioOperatore, idOrtomioOperatoreDestinatario:idOrtomioOperatoreDestinatario}, function (data) {
                            contractor.loadTimesheetTemplate();
                        });
                    }

                });
                $("[data-interaction=elencoOperatoriTemplate]").select2();

            });
        }
    };
    this.loadSoggettoAllegati = function () {
        if ($("[data-interaction=soggetto_allegati]").length) {
            $.get("soggetto_allegato", {id_subgrid: $("[data-interaction=soggetto_allegati]").attr("data-soggettoid")}, function (data) {
                $("[data-interaction=soggetto_allegati]").html(data);
            });
        }
    };

    this.bindEventsCompany = function () {
        $("[data-interaction=sediOperative]").unbind("click").bind("click", function () {
            var id = $(this).attr("data-id");
            contractor.stampa([id], dt);
        });
    };

    this.clearInterval = function () {
        // se un interval è già in esecuzione lo elimino
        if (typeof contractor.constructor.interval != "undefined")
            clearInterval(contractor.constructor.interval);
    };

    this.startInterval = function () {
        this.clearInterval();

        contractor.constructor.interval = setInterval(function () {
            contractor.verifyStampeInCoda();
        }, 10000);

        contractor.verifyStampeInCoda();
    }
};

contractor.interval = undefined;