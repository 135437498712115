var acl = function (dataTable) {

    this.bindEvents = function () {
        // controllo le associazioni ai gruppi già esistenti
        $('[data-interaction=bindGroup]').each(function () {
            var idGroup = $(this).attr('data-id-group');
            var idUser = $(this).attr('data-id-user');
            var self = this;

            app.block(1);
            $.post(dataTable.baseUrl + "/group", {operation: 'bound', idUser: idUser, idGroup: idGroup})
                .success(function (data) {
                    if(data.response) {
                        if(data.message) $(self).attr("checked", "checked");
                    }  else {
                        app.warning("", data.message);
                    }
                    app.block(0);
                })
                .error(function () {
                    app.block(0);
                    console.error("Ajax error!");
                    app.error("", "Ajax error!");
                });
        });

        // gestisco le check per l'associazione al gruppo
        $('[data-interaction=bindGroup]').unbind('change').bind('change', function () {
            var val = $(this).is(':checked');
            var idUser = $(this).attr('data-id-user');
            var idGroup = $(this).attr('data-id-group');
            var url = $(this).attr('data-url');

            app.block(1);
            $.post(url, {idUser: idUser, idGroup: idGroup, val: val, operation: 'bind'})
                .success(function (data) {
                    if(data.response) {
                        app.success("", data.message);
                    } else {
                        app.warning("", data.message);
                    }
                    dataTable.table.draw('page');
                    app.block(0);
                })
                .error(function () {
                    console.error("Ajax error!");
                    app.error("", "Ajax error!");
                    app.block(0);
                    dataTable.table.draw('page');
                });
        });

        // controllo le associazioni alle acl già esistenti
        $("#tblFeature").find('tbody').find('tr').each(function () {
            var idModel = $("#idModel").val();
            var idFeature = $(this).attr('data-id');
            var type = $("#type").val();
            var self = this;

            app.block(1);
            $.post(dataTable.baseUrl + "/feature", {operation: 'bound', idModel: idModel, idFeature: idFeature, type: type})
                .success(function (data) {
                    if(data.response) {
                        var readonly = false;
                        $.each(Object.keys(data.message), function (i, v) {
                            if(data.message[v]) {
                                $(self).find('[data-action="' + v + '"]').attr("checked", "checked");
                                if(v == "allow" || v == "deny") {
                                    readonly = true;
                                }
                            }
                        });
                        if(readonly) {
                            $(self).find('[data-action="index"]').attr("disabled", "disabled");
                            $(self).find('[data-action="create"]').attr("disabled", "disabled");
                            $(self).find('[data-action="store"]').attr("disabled", "disabled");
                            $(self).find('[data-action="show"]').attr("disabled", "disabled");
                            $(self).find('[data-action="edit"]').attr("disabled", "disabled");
                            $(self).find('[data-action="update"]').attr("disabled", "disabled");
                            $(self).find('[data-action="destroy"]').attr("disabled", "disabled");
                        } else {
                            $(self).find('[data-action="index"]').removeAttr("disabled");
                            $(self).find('[data-action="create"]').removeAttr("disabled");
                            $(self).find('[data-action="store"]').removeAttr("disabled");
                            $(self).find('[data-action="show"]').removeAttr("disabled");
                            $(self).find('[data-action="edit"]').removeAttr("disabled");
                            $(self).find('[data-action="update"]').removeAttr("disabled");
                            $(self).find('[data-action="destroy"]').removeAttr("disabled");
                        }
                        if(data.message && data.message.not) $(self).css('background-color', 'lightsalmon');
                        else $(self).css('background-color', 'inherit');
                    }  else {
                        app.warning("", data.message);
                    }
                    app.block(0);
                })
                .error(function () {
                    app.block(0);
                    console.error("Ajax error!");
                    app.error("", "Ajax error!");
                });
        });

        // gestisco le check per l'associazione alle acl
        $('[data-interaction=bindAcl]').unbind('change').bind('change', function () {
            var $tr = $(this).closest('tr');
            var url = $(this).attr('data-url');
            var data = {
                operation: 'bind',
                idModel: $(this).attr('data-id-model'),
                idFeature: $(this).attr('data-id-feature'),
                type: $(this).attr('data-type'),
                index: $tr.find('[data-action=index]').is(":checked"),
                create: $tr.find('[data-action=create]').is(":checked"),
                store: $tr.find('[data-action=store]').is(":checked"),
                show: $tr.find('[data-action=show]').is(":checked"),
                edit: $tr.find('[data-action=edit]').is(":checked"),
                update: $tr.find('[data-action=update]').is(":checked"),
                destroy: $tr.find('[data-action=destroy]').is(":checked"),
                allow: $tr.find('[data-action=allow]').is(":checked"),
                deny: $tr.find('[data-action=deny]').is(":checked"),
                menu: $tr.find('[data-action=menu]').is(":checked")
            };

            // setto l'acl
            app.block(1);
            $.post(url, data)
                .success(function (data) {
                    if(data.response) {
                        app.success("", data.message);
                    } else {
                        app.warning("", data.message);
                    }
                    dataTable.table.draw('page');
                    app.block(0);
                })
                .error(function () {
                    app.block(0);
                    console.error("Ajax error!");
                    app.error("", "Ajax error!");
                    dataTable.table.draw('page');
                });
        });
    };


    /**
     * BINDING
     */
    this.bindGroup = function () {
        if(dataTable.table.rows({selected: true}).data()[0]) {
            var id = parseInt(dataTable.table.rows({selected: true}).data()[0][0]);
            var url = dataTable.baseUrl + "/group";

            app.block(1);
            $.post(url, {id: id, dt: dataTable.configuration})
                .success(function (data) {
                    $("#myModal").find('.modal-body').html(data);
                    $("#myModal").modal('show');
                    app.block(0);
                })
                .error(function () {
                    app.block(0);
                    app.error("", "Ajax error!");
                });
        } else {
            app.warning("", "Devi selezionare almeno un utente!");
        }
    };

    this.bindAcl = function () {
        if(dataTable.table.rows({selected: true}).data()[0]) {
            var id = parseInt(dataTable.table.rows({selected: true}).data()[0][0]);
            var type = $("#type").val();
            var url = dataTable.baseUrl + "/feature";

            app.block(1);
            $.post(url, {id: id, dt: dataTable.configuration, type: type})
                .success(function (data) {
                    $("#myModal").find('.modal-body').html(data);
                    $("#myModal").modal('show');
                    app.block(0);
                })
                .error(function () {
                    app.block(0);
                    app.error("", "Ajax error!");
                });
        } else {
            app.warning("", "Devi selezionare almeno un utente!");
        }
    };

    /**
     * FORMATTER
     */
    // formatter per la checkbox di associazione al gruppo
    formatter.bindGroup = function (table, td, cell_data, row_data, row_index, col_index) {
        var idGroup = cell_data;
        var idUser = $("#idUser").val();

        return '<input data-interaction="bindGroup" data-id-group="' + idGroup + '" data-id-user="' + idUser + '" data-url="' + dataTable.baseUrl + '/group" type="checkbox">';
    };

    // formatter per le checkbox di associazione alle acl
    var actions = {
        4: 'allow',
        5: 'deny',
        6: 'index',
        7: 'create',
        8: 'store',
        9: 'show',
        10: 'edit',
        11: 'update',
        12: 'destroy',
        13: 'menu'
    };
    formatter.bindAcl = function (table, td, cell_data, row_data, row_index, col_index) {
        var idFeature = cell_data;
        var id = $("#idModel").val();
        var type = $("#type").val();

        return '<input type="checkbox" data-interaction="bindAcl" data-action="' + actions[col_index] + '" data-id-feature="' + idFeature + '" data-id-model="' + id + '" data-type="' + type + '" data-url="' + dataTable.baseUrl + '/feature">';
    };

    /**
     * CUSTOM BUTTON
     */


    this.bindEvents();

};